import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import '../styles/Emissor.css';
import { useDispatch } from "react-redux";
import axios from "axios";
import { setEmissor } from "../redux/actions";
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import Emissors from "../components/Emissors";
import { formatCnpj, formatCpf, formatDocument, formatRg } from "../utils/Tools";
import DevedorSolidario from "../components/DevedorSolidario";
import { BsArrowLeft } from "react-icons/bs";

const Emissor = () => {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    const emissor = JSON.parse(localStorage.getItem('emissor'));
    const dispatch = useDispatch();
    const storedData = JSON.parse(localStorage.getItem('notaComercial'));

    const [dadosBancarios, setDadosBancarios] = useState('');
    const [conta, setConta] = useState('');
    const [agency, setAgency] = useState('');
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState('');
    const [bankNumber, setBankNumber] = useState('');
    const [devedor, setDevedor] = useState(true);
    const [fiel, setFiel] = useState(false);
    const [avalista, setAvalista] = useState(false);
    const [dRazao, setDRazao] = useState('');
    const [dCnpj, setDCnpj] = useState('');
    const [dCep, setDCep] = useState('');
    const [dRua, setDRua] = useState('');
    const [dCidade, setDCidade] = useState('');
    const [dEstado, setDEstado] = useState('');
    const [dNumero, setDNumero] = useState('');
    const [dBairro, setDBairro] = useState('');
    const [dComplemento, setDComplemento] = useState('');
    const [dEmail, setDEmail] = useState('');
    const [representante, setRepresentante] = useState('');
    const [dCpf, setDCpf] = useState('');
    const [cargo, setCargo] = useState('');

    const [dAdress, setDAdress] = useState('');
    const [fName, setFName] = useState('');
    const [fOcupacao, setFOcupacao] = useState('');
    const [fCep, setFCep] = useState('');
    const [fRua, setFRua] = useState('');
    const [fCidade, setFCidade] = useState('');
    const [fEstado, setFEstado] = useState('');
    const [fNumero, setFNumero] = useState('');
    const [fComplemento, setFComplemento] = useState('');
    const [fCpf, setFCpf] = useState('');
    const [fCnpj, setFCnpj] = useState('');
    const [fRg, setFRg] = useState('');
    const [fEmail, setFEmail] = useState('');
    const [avalistas, setAvalistas] = useState([{ aNome: '', aCpf: '', aRg: '', aEmail: '', aCep: '', aRua: '', aCidade: '', aEstado: '', aNumero: '', aComplemento: '', aProfissao: '', aNacionalidade: '', aEstadoCivil: '', aDataNascimento: '' }]);
    const [meioPagamento, setMeioPagamento] = useState('[X] Transferência Eletrônica Disponível (TED)');
    const [erroValidacao, setErroValidacao] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [tipo, setTipo] = useState("PF"); // Inicialmente, define como pessoa física (PF)
    const [solidario, setSolidario] = useState(false);
    const [cadastrados, setCadastrados] = useState(true);
    const [devedorInfo, setDevedorInfo] = useState({});
    const handleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    const updateFormData = (newData) => {
        setDevedorInfo(newData);
    };
    useEffect(() => {
        handleExpand()

    }, [])
    //const [loading, setLoading] = useState(false);

    const keywordsToPrioritize = ['caixa eco', 'itaú uni', 'bco do brasil', 'bradesco s', 'santander', 'original', 'banco inter', 'bdigital'];

    const sortedBanks = [...banks].sort((a, b) => {
        const bankNameA = a.name ? a.name.toLowerCase() : '';
        const bankNameB = b.name ? b.name.toLowerCase() : '';

        const priorityA = getPriority(bankNameA);
        const priorityB = getPriority(bankNameB);

        if (priorityA !== priorityB) {
            return priorityA - priorityB; // Coloque o banco com prioridade maior primeiro
        } else {
            return bankNameA.localeCompare(bankNameB); // Ordem alfabética padrão
        }
    });
    const toggleTipo = () => {
        setTipo(tipo === "PF" ? "PJ" : "PF");
    };
    const toggleSolidario = () => {
        setSolidario(!solidario);
    }
    function getPriority(name) {
        for (let i = 0; i < keywordsToPrioritize.length; i++) {
            if (name.includes(keywordsToPrioritize[i])) {
                return i;
            }
        }
        return keywordsToPrioritize.length;
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        fetch('https://brasilapi.com.br/api/banks/v1')
            .then(response => response.json())
            .then(data => setBanks(data))
            .catch(error => console.error('Ocorreu um erro ao buscar os bancos:', error));
        setDadosBancarios(`Banco: ${selectedBank}-${bankNumber}/Agencia: ${agency}/Conta: ${conta}`);
    },);

    const handleBankChange = (event) => {
        const selectedBankName = event.target.value;
        setSelectedBank(selectedBankName);
        const selectedBankData = banks.find(bank => bank.name === selectedBankName);

        if (selectedBankData) {
            setBankNumber(selectedBankData.code);
        }
        else if (selectedBankName === 'BNK Digital') {
            setBankNumber(547);
        }
        else {
            setBankNumber('');
        }
    };

    const handleBankNumberChange = (event) => {
        const bankNumber = event.target.value;
        setBankNumber(bankNumber);

        const selectedBankData = banks.find(bank => bank.code == bankNumber);

        if (selectedBankData) {
            setSelectedBank(selectedBankData.name);
        } else {
            setSelectedBank('');
        }
    };

    const handleSearch = async (cep) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            const data = response.data;

            setFRua(data.logradouro || "");
            setFNumero("");
            setFComplemento("");
            setFCidade(data.localidade || "");
            setFEstado(data.uf || "");
        } catch (error) {
            console.error('Erro ao buscar endereço:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCepChange = (e) => {
        const newCep = e.target.value.replace(/\D/g, "");
        setFCep(newCep);

        if (newCep.length === 8) {
            handleSearch(newCep);
        }
    };

    const toggleDevedorVisibility = () => {
        setDevedor(!devedor);
    };

    const handleAvalistaChange = (e, index, field) => {
        const newAvalistas = [...avalistas];
        newAvalistas[index][field] = e.target.value;
        setAvalistas(newAvalistas);
        console.log(newAvalistas, 'aqui');
        console.log(avalistas);
    };

    const removeAvalista = (indexToRemove) => {
        const updatedAvalistas = [...avalistas];
        updatedAvalistas.splice(indexToRemove, 1); // Remove o avalista pelo índice
        setAvalistas(updatedAvalistas);
    };

    const toggleFielVisibility = () => {
        setFiel(!fiel);
    };

    const toggleCadastrados = () => {
        setCadastrados(!cadastrados);
    };

    const toggleAvalistaVisibility = () => {
        setAvalista(!avalista);
    };

    const handleSearch2 = async () => {
        try {
            setIsLoading2(true);
            const response = await axios.get(`https://viacep.com.br/ws/${dCep}/json/`);
            setDAdress(response.data);
            setDBairro(response.data.bairro);
            setDCidade(response.data.localidade);
            setDEstado(response.data.uf);
            setDRua(response.data.logradouro);
        } catch (error) {
            console.error('Error fetching address:', error);
        } finally {
            setIsLoading2(false);
        }
    };

    const addAvalista = () => {
        setAvalistas([...avalistas, { aNome: '', aCpf: '', aRg: '', aEmail: '', aCep: '', aRua: '', aCidade: '', aEstado: '', aNumero: '', aComplemento: '', aProfissao: '', aNacionalidade: '', aEstadoCivil: '', aDataNascimento: '' }]);
    };
    const validateFields = (data) => {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const value = data[key];
                if (key === 'complemento') {
                    continue;
                }
                if (key === 'garantia') {
                    continue;
                }
                if (key === 'gTipo') {
                    continue;
                }
                if (key === 'gNatureza') {
                    continue;
                }
                if (key === 'gDescricao') {
                    continue;
                }
                if (key === 'gDocumento') {
                    continue;
                }
                if (key === 'gProprietario') {
                    continue;
                }
                if (key === 'gQuantidade') {
                    continue;
                }
                if (key === 'gValor') {
                    continue;
                }
                if (!value || (typeof value === 'string' && value === '')) {
                    return `O campo ${key} não pode estar vazio.`;
                }

            }
        }
        return null; // Todos os campos estão preenchidos
    };

    const sendInfos = async () => {
       // console.log(avalistas, 'avalistosaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
        const dados = {
            userRef: user.data.email,
            razaoSocial: dRazao,
            cnpj: dCnpj,
            email: dEmail,
            cpf: dCpf,
            representante: representante,
            cargo: cargo,
            banco: selectedBank,
            bankNumber: bankNumber,
            conta:conta,
            agency: agency,
            cep: dCep,
            rua: dRua,
            numero: dNumero,
            bairro: dBairro,
            cidade: dCidade,
            estado: dEstado,
            complemento: dComplemento,
            fielDepositario: {
                nome: fName,
                ocupacao: fOcupacao,
                cep: fCep,
                rua: fRua,
                numero: fNumero,
                complemento: fComplemento,
                cidade: fCidade,
                estado: fEstado,
                cpf: fCpf,
                rg: fRg,
                email: fEmail
            },
            devedorSolidario: devedorInfo,
            avalistas: avalistas,
            meioPagamento,
        }
        const resultadoValidacao = validateFields(dados);
        if (resultadoValidacao) {
            setErroValidacao(resultadoValidacao);
            return;
        }
        console.log(dados,'dados')
        setErroValidacao(null);
        const url = process.env.REACT_APP_BASE_URL;
        const localhost = 'http://localhost:6024';
        const response = await axios.post(`${url}/emissor`, dados, {
            headers: {

                'Content-Type': 'application/json',
            },
        });
        if (response.status === 201) {
            console.log('Emissor criado com sucesso!');
            // Trate a resposta ou faça alguma ação adicional se necessário
        }
        localStorage.setItem('emissorPreenchido', true);
        localStorage.setItem('emissor', JSON.stringify(dados));
        dispatch(setEmissor(dados));
        window.alert('Emissor Cadastrado!');
        navigate('/docgen')
    }

    const handleSearchAva = async (cep, index) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            const data = response.data;

            const newAvalistas = [...avalistas];
            const currentAvalista = newAvalistas[index];

            currentAvalista.aRua = data.logradouro || "";
            currentAvalista.aNumero = "";
            currentAvalista.aBairro = data.bairro || "";
            currentAvalista.aCidade = data.localidade || "";
            currentAvalista.aEstado = data.uf || "";

            setAvalistas(newAvalistas);
        } catch (error) {
            console.error('Erro ao buscar endereço:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCepChangeAva = (e, index, field) => {
        const newCep = e.target.value.replace(/\D/g, "");
        const newAvalistas = [...avalistas];
        newAvalistas[index][field] = e.target.value;
        setAvalistas(newAvalistas);

        if (newCep.length === 8) {
            handleSearchAva(newCep, index);
        }
    };

    return (
        <body className="invesBody">
            <div className='fixed'>
                <Header selected={'emissor'} />
            </div>
            <main className="investidorDoc2">
                <div onClick={toggleCadastrados} className="buttonEmissor">
                    {cadastrados && <div className="buttonToggle">
                        Cadastrar novo emissor
                    </div>}
                </div>
                {
                    cadastrados ?
                        <Emissors />
                        :
                        <div className="mainDoc22">
                            <form className="formDoc1">
                                <div className="divTitleDoc33" onClick={() => { toggleCadastrados() }}>
                                    <h1 className="h1Doc"><BsArrowLeft style={{ marginRight: '0.5rem' }} />Cadastrar novo emissor</h1>
                                </div>
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">
                                        Razão Social do Emissor:
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Razão Social"
                                        className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                        value={dRazao}
                                        onChange={(e) => { setDRazao(e.target.value) }}
                                        maxLength={120} />
                                </div>
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">
                                        CNPJ:
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="CNPJ"
                                        className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                        value={formatCnpj(dCnpj)}
                                        onChange={(e) => { setDCnpj(e.target.value) }}
                                        maxLength={18} />
                                </div>
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">
                                        CEP:
                                    </label>
                                    <InputMask
                                        mask="99999-999"
                                        maskChar=""
                                        type="text"
                                        placeholder="CEP"
                                        className={`inputsDoc`}
                                        value={dCep}
                                        onChange={(e) => { setDCep(e.target.value) }}
                                        onBlur={handleSearch2}
                                    />
                                </div>
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">
                                        Número:
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Número"
                                        className={`inputsDoc`}
                                        value={dNumero}
                                        onChange={(e) => { setDNumero(e.target.value) }}
                                    />
                                </div>
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">
                                        Complemento:
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Complemento"
                                        className={`inputsDoc`}
                                        value={dComplemento}
                                        onChange={(e) => { setDComplemento(e.target.value) }}
                                    />
                                </div>
                                {isLoading2 ? (
                                    <p className="loading">Carregando...</p>
                                ) : (
                                    <div className="divInputsDoc2">
                                        <div className="divInputsDoc">
                                            <label htmlFor="" className="labelInputsDoc">
                                                Rua:
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Rua"
                                                className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                                value={dRua}
                                                onChange={(e) => { setDRua(e.target.value) }}
                                            />
                                        </div>

                                        <div className="divInputsDoc">
                                            <label htmlFor="" className="labelInputsDoc">
                                                Bairro:
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Complemento"
                                                className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                                value={dBairro}
                                                onChange={(e) => { setDBairro(e.target.value) }}
                                            />
                                        </div>

                                        <div className="divInputsDoc">
                                            <label htmlFor="" className="labelInputsDoc">
                                                Cidade:
                                            </label>
                                            <input
                                                placeholder="Cidade"
                                                type="text"
                                                className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                                value={dCidade}
                                                onChange={(e) => { setDCidade(e.target.value) }}
                                            />
                                        </div>
                                        <div className="divInputsDoc">
                                            <label htmlFor="" className="labelInputsDoc">
                                                Estado:
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Estado"
                                                className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                                value={dEstado}
                                                onChange={(e) => { setDEstado(e.target.value) }}
                                            />
                                        </div>
                                    </div>)
                                }
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">
                                        e-mail:
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Email"
                                        className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                        value={dEmail}
                                        onChange={(e) => { setDEmail(e.target.value) }}
                                        maxLength={40} />
                                </div>
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">
                                        Representante Legal
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Representante Legal"
                                        className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                        value={representante}
                                        onChange={(e) => { setRepresentante(e.target.value) }}
                                        maxLength={40} />
                                </div>
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">
                                        CPF
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="CPF"
                                        className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                        value={formatCpf(dCpf)}
                                        onChange={(e) => { setDCpf(e.target.value) }}
                                        maxLength={14} />
                                </div>
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">
                                        Cargo
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Cargo"
                                        className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                        value={cargo}
                                        onChange={(e) => { setCargo(e.target.value) }}
                                        maxLength={40} />
                                </div>
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">
                                        Dados Bancários
                                    </label>
                                    <select value={selectedBank} onChange={handleBankChange} className="selectDoc">
                                        <option value="" className="optionInputsDoc">Selecione um banco</option>
                                        <option value="BNK Digital" className="optionInputsDoc">BNK Digital</option>
                                        {sortedBanks.map((bank, index) => (
                                            <option key={index} value={bank.name} className="optionInputsDoc">
                                                {bank.name}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">Numero do Banco</label>
                                    <input
                                        type='text'
                                        name="Número do banco"
                                        placeholder="Selecione um banco"
                                        className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                        value={bankNumber}
                                        onChange={handleBankNumberChange}
                                        maxLength={20}
                                    />
                                </div>
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">
                                        Agência:
                                    </label>
                                    <input
                                        placeholder="12345"
                                        type="text"
                                        className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                        value={agency}
                                        onChange={(e) => { setAgency(e.target.value) }}
                                        maxLength={8} />
                                </div>
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">
                                        Conta:
                                    </label>
                                    <input
                                        placeholder="12345"
                                        type="text"
                                        className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                        value={conta}
                                        onChange={(e) => { setConta(e.target.value) }}
                                        maxLength={10} />
                                </div>
                                <div className="divInputsDoc">
                                    <label className="labelInputsDoc">Meio de Pagamento</label>
                                    <select name="meioDePagamento" value={meioPagamento} onChange={(e) => { setMeioPagamento(e.target.value) }} className="selectDoc">
                                        <option value={`[X] Transferência Eletrônica Disponível (TED)`}>Transferência Eletrônica Disponível (TED)</option>
                                        <option value={`[X] Dação em Pagamento`}>Dação em Pagamento</option>
                                    </select>
                                </div>

                                <div className="newButtonEm" onClick={toggleAvalistaVisibility}>
                                    {avalista ? "Ocultar Cadastro de Avalista" : "Exibir Cadastro de Avalista +"}
                                </div >
                                {
                                    avalista ?
                                        <div className=''>
                                            {avalistas.map((avalista, index) => (
                                                <div key={index} className="mainAvalista">
                                                    <h1 className="h1DocAva">Avalista {index + 1}</h1>
                                                    <div className="divInputsDocAva">
                                                        <label htmlFor="" className="labelInputsDoc" style={{ marginTop: '2vh' }}>
                                                            Nome:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aNome}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aNome')}
                                                            maxLength={40}
                                                        />
                                                    </div>
                                                    <div className="divInputsDocAva">
                                                        <label htmlFor="" className="labelInputsDoc" style={{ marginTop: '2vh' }}>
                                                            CPF/CNPJ:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={formatDocument(avalista.aCpf)}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aCpf')}
                                                            maxLength={19}
                                                        />
                                                    </div>
                                                    <div className="divInputsDocAva">
                                                        <label htmlFor="" className="labelInputsDoc" style={{ marginTop: '2vh' }}>
                                                            RG:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={formatRg(avalista.aRg)}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aRg')}
                                                            maxLength={16}
                                                        />

                                                    </div>
                                                    <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                                        <label htmlFor="" className="labelInputsDoc">
                                                            Email:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aEmail}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aEmail')}
                                                        />
                                                    </div>
                                                    <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                                        <label htmlFor="nacionalidade" className="labelInputsDoc">
                                                            Nacionalidade:
                                                        </label>
                                                        <select
                                                            className="inputsDocAva"
                                                            value={avalista.aNacionalidade}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aNacionalidade')}
                                                            id="nacionalidade"
                                                        >
                                                            <option value="Brasileiro(a)">Brasileiro(a)</option>
                                                            <option value="Americano(a)">Americano(a)</option>
                                                            <option value="Canadense">Canadense</option>
                                                            <option value="Frances(a)">Frances(a)</option>
                                                            <option value="Alemão(a)">Alemão(a)</option>
                                                            <option value="Italiano(a)">Italiano(a)</option>
                                                            <option value="Japones(a)">Japones(a)</option>
                                                            <option value="Chines(a)">Chines(a)</option>
                                                            <option value="Indiano(a)">Indiano(a)</option>
                                                            <option value="Russo(a)">Russo(a)</option>
                                                            <option value="Mexicano(a)">Mexicano(a)</option>
                                                            <option value="Outra">Outra</option>
                                                        </select>
                                                    </div>


                                                    <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                                        <label htmlFor="" className="labelInputsDoc">
                                                            Estado Civil:
                                                        </label>
                                                        <select
                                                            className={`selectDoc`}

                                                            value={avalista.aEstadoCivil}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aEstadoCivil')}
                                                        >
                                                            <option value="solteiro">Solteiro</option>
                                                            <option value="casado">Casado</option>
                                                            <option value="divorciado">Divorciado</option>
                                                            <option value="viuvo">Viúvo</option>
                                                            {/* Adicione mais opções conforme necessário */}
                                                        </select>
                                                    </div>

                                                    <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                                        <label htmlFor="" className="labelInputsDoc">
                                                            Profissão:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aProfissao}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aProfissao')}
                                                        />
                                                    </div>
                                                    <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                                        <label htmlFor="" className="labelInputsDoc">
                                                            Data de Nascimento:
                                                        </label>
                                                        <input
                                                            type="date"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aDataNascimento}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aDataNascimento')}
                                                            maxLength={40}
                                                        />
                                                    </div>
                                                    <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                                        <label htmlFor="" className="labelInputsDoc">
                                                            CEP:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aCep}
                                                            onChange={(e) => handleCepChangeAva(e, index, 'aCep')}
                                                        />
                                                    </div>
                                                    <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                                        <label htmlFor="" className="labelInputsDoc">
                                                            Rua:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aRua}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aRua')}
                                                        />
                                                    </div>
                                                    <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                                        <label htmlFor="" className="labelInputsDoc">
                                                            Número:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aNumero}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aNumero')}
                                                            maxLength={14}
                                                        />
                                                    </div>
                                                    <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                                        <label htmlFor="" className="labelInputsDoc">
                                                            Bairro:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aBairro}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aBairro')}
                                                        />
                                                    </div>
                                                    <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                                        <label htmlFor="" className="labelInputsDoc">
                                                            Complemento:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aComplemento}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aComplemento')}
                                                        />
                                                    </div>
                                                    <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                                        <label htmlFor="" className="labelInputsDoc">
                                                            Cidade:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aCidade}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aCidade')}
                                                        />
                                                    </div>
                                                    <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                                        <label htmlFor="" className="labelInputsDoc">
                                                            Estado:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aEstado}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aEstado')}
                                                        />
                                                    </div>

                                                    <div className="addButton2" onClick={() => removeAvalista(0)}>Remover Avalista</div>
                                                </div>
                                            ))}
                                            <div className="addButton" onClick={addAvalista}>
                                                + Adicionar Avalista
                                            </div></div> : <br />}
                                <div onClick={toggleFielVisibility} className="newButtonEm">
                                    {fiel ? "Ocultar Cadastro de Fiel Depositário" : "Exibir Cadastro de Fiel Depositário +"}
                                </div>
                                {fiel ?
                                    <form className="mainAvalista">
                                        <div className="divTitleDoc">
                                            <h1 className="h1Doc">FIEL DEPOSITÁRIO</h1>

                                        </div>
                                        <div type="button" style={{
                                            width: '100%',
                                            padding: '10px',
                                            cursor: 'pointer',
                                            backgroundColor: '#2C3E50',
                                            color: 'white',
                                            textAlign: 'center',
                                            borderRadius: '5px',
                                            marginBottom: '10px'
                                        }} onClick={toggleTipo}>
                                            {tipo === "PF" ? "Pessoa Física (CPF)" : "Pessoa Jurídica (CNPJ)"}
                                        </div>
                                        {tipo === "PF" ? (
                                            <div className="divInputsDoc">
                                                <label htmlFor="" className="labelInputsDoc">
                                                    Nome:
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`inputsDoc`}
                                                    value={fName}
                                                    onChange={(e) => { setFName(e.target.value) }}
                                                />
                                            </div>
                                        ) : (
                                            <div className="divInputsDoc">
                                                <label htmlFor="" className="labelInputsDoc">
                                                    Razão Social:
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`inputsDoc`}
                                                    value={fName} // Você precisa criar o estado fCnpj
                                                    onChange={(e) => { setFName(e.target.value) }}
                                                />
                                            </div>
                                        )}

                                        <div className="divInputsDoc">
                                            <label htmlFor="" className="labelInputsDoc">
                                                Ocupação:
                                            </label>
                                            <input
                                                type="text"
                                                className={`inputsDoc`}
                                                value={fOcupacao}
                                                onChange={(e) => { setFOcupacao(e.target.value) }}
                                                maxLength={40} />
                                        </div>
                                        <div className="divInputsDoc" style={{ width: '100%', display: 'flex', justifyContent: 'start' }}>
                                            <label htmlFor="" className="labelInputsDoc">
                                                CEP:
                                            </label>
                                            <InputMask
                                                mask="99999-999"
                                                maskChar=""
                                                type="text"
                                                className={`inputsDoc`}
                                                value={fCep}
                                                onChange={handleCepChange}
                                                style={{ width: '45%' }}
                                            />
                                        </div>
                                        {isLoading ? (
                                            <p className="loading">Carregando...</p>
                                        ) : (
                                            <div className="divFull">
                                                <div className="rowDiv">
                                                    <div className="divInputsDoc">
                                                        <label htmlFor="" className="labelInputsDoc">
                                                            Rua:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`inputsDoc`}
                                                            value={fRua}
                                                            onChange={(e) => setFRua(e.target.value)}
                                                            maxLength={40}
                                                        />
                                                    </div>
                                                    <div className="divInputsDoc">
                                                        <label htmlFor="" className="labelInputsDoc">
                                                            Número:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`inputsDoc`}
                                                            value={fNumero}
                                                            onChange={(e) => setFNumero(e.target.value)}
                                                            maxLength={40}
                                                        />
                                                    </div>
                                                    <div className="divInputsDoc">
                                                        <label htmlFor="" className="labelInputsDoc">
                                                            Complemento:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`inputsDoc`}
                                                            value={fComplemento}
                                                            onChange={(e) => setFComplemento(e.target.value)}
                                                            maxLength={40}
                                                        />

                                                    </div>
                                                </div>


                                            </div>
                                        )}
                                        <div className="divInputsDoc">
                                            <label htmlFor="" className="labelInputsDoc">
                                                Cidade:
                                            </label>
                                            <input
                                                type="text"
                                                className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                                value={fCidade}
                                                onChange={(e) => setFCidade(e.target.value)}
                                                maxLength={40}
                                            />
                                        </div>
                                        <div className="divInputsDoc">
                                            <label htmlFor="" className="labelInputsDoc">
                                                Estado:
                                            </label>
                                            <input
                                                type="text"
                                                className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                                value={fEstado}
                                                onChange={(e) => setFEstado(e.target.value)}
                                                maxLength={40}
                                            />
                                        </div>
                                        {tipo === "PF" ? (
                                            <div className="divInputsDoc">
                                                <label htmlFor="" className="labelInputsDoc">
                                                    CPF:
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                                    value={formatCpf(fCpf)}
                                                    onChange={(e) => { setFCpf(e.target.value) }}
                                                    maxLength={14}
                                                />
                                            </div>
                                        ) : (
                                            <div className="divInputsDoc">
                                                <label htmlFor="" className="labelInputsDoc">
                                                    CNPJ:
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                                    value={formatCnpj(fCnpj)} // Você precisa criar o estado fCnpj
                                                    onChange={(e) => { setFCnpj(e.target.value) }}
                                                    maxLength={18}
                                                />
                                            </div>
                                        )}
                                        <div className="divInputsDoc">
                                            <label htmlFor="" className="labelInputsDoc">
                                                e-mail:
                                            </label>
                                            <input
                                                type="text"
                                                className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                                value={fEmail}
                                                onChange={(e) => { setFEmail(e.target.value) }}
                                                maxLength={40} />
                                        </div>
                                    </form>
                                    : <br />}
                                {/* <div onClick={toggleSolidario} className="newButtonEm">
                                    {solidario ? "Ocultar Cadastro de Devedor Solidário" : "Exibir Cadastro de Devedor Solidário  +"}
                                </div>
                                {solidario ?
                                    <DevedorSolidario updateFormData={updateFormData} />
                                    :
                                    <br />} */}

                                <div className="buttonEmissor1" >
                                    <div onClick={() => setCadastrados(false)} className="emissorBack" > Voltar</div>
                                    <div onClick={sendInfos} className="emissorSend">Cadastrar Emissor</div>
                                </div>
                                {erroValidacao && (
                                    <div className="mensagemErro">
                                        Erro de validação: {erroValidacao}
                                    </div>
                                )}</form>
                        </div>}
            </main >

        </body >
    )
}

export default Emissor;