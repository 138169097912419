import React, { useEffect, useState } from 'react';
import '../styles/Login.css';
import { useNavigate, useParams } from 'react-router-dom';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { setCalcData, setDados } from '../redux/actions';
import { MdLockOutline, MdPersonOutline } from 'react-icons/md';


const authenticateUserInternal = async (authEmail, authPassword, endpoint, navigate, dispatch) => {
    try {
        console.log('Authenticating user internally...');
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: authEmail,
                password: authPassword,
            }),
        });

        const json = await response.json();

        if (response.status === 201) {
            console.log('Authentication successful!');
            localStorage.setItem('userToken', json.token);
            localStorage.setItem('user', JSON.stringify(json));
            localStorage.setItem('calcData', false);
            dispatch(setDados(json));
            dispatch(setCalcData(false));
            navigate('/home');
        } else {
            console.log('Authentication failed.');
            window.alert('Informações Incorretas. Revise e tente novamente.');
        }
    } catch (error) {
        console.error(error);
        window.alert('Informações Incorretas. Revise e tente novamente.');
    }
};

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const emailParam = queryParams.get("emailParam");
    const senhaParam = queryParams.get("senhaParam");

    useEffect(() => {
        console.log('useEffect triggered', email, password, emailParam, senhaParam);
        if (emailParam && senhaParam) {
            console.log('Checking URL parameters for login...');
            authenticateUserInternal(emailParam, senhaParam, process.env.REACT_APP_BASE_URL +'/master/authenticate', navigate, dispatch);
        }
    }, [emailParam, senhaParam, navigate, dispatch]);

    
    const [user, setUser] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [screen, setScreen] = useState('');
    const [userType, setUserType] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };


    useEffect(() => {
        console.log('useEffect triggered');
        if (emailParam && senhaParam) {
            console.log('Checking URL parameters for login...');
            authenticateUserInternal(emailParam, senhaParam, process.env.REACT_APP_BASE_URL +'/master/authenticate', navigate, dispatch);
        }
    }, [emailParam, senhaParam, navigate, dispatch]);

    const authenticateUser = async (authEmail, authPassword, endpoint) => {
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: authEmail,
                    password: authPassword,
                }),
            });

            const json = await response.json();

            if (response.status === 201) {
                localStorage.setItem('userToken', json.token);
                localStorage.setItem('user', JSON.stringify(json));
                localStorage.setItem('calcData', false);
                dispatch(setDados(json));
                dispatch(setCalcData(false));
                navigate('/home');
            } else {
                window.alert('Informações Incorretas. Revise e tente novamente.');
            }
        } catch (error) {
            console.error(error);
            window.alert('Informações Incorretas. Revise e tente novamente.');
        }
    };
    const handleLogin = async () => {
        console.log('Handling login...');
        if (emailParam && senhaParam) {
            authenticateUserInternal(emailParam, senhaParam, process.env.REACT_APP_BASE_URL  + '/master/authenticate', navigate, dispatch);
        } else {
            //aut
            authenticateUserInternal(email, password, process.env.REACT_APP_BASE_URL  +'/master/authenticate', navigate, dispatch);
        }
    };

    const handleFuncionario = async () => {
        if (emailParam && senhaParam) {
            authenticateUser(emailParam, senhaParam, process.env.REACT_APP_BASE_URL +'/employee/authenticate');
        } else {
            authenticateUser(email, password, process.env.REACT_APP_BASE_URL +'/employee/authenticate');
        }
    };

    const handleForgot = () => {
        navigate('/update');
    };

    const getSizes = () => {
        var windowWidth = window.innerWidth;
        var windowHeight = window.innerHeight;
        if (windowHeight < 1000 && windowWidth < 1250) {
            setScreen('mobile');
        } else if (windowHeight > 1000 && windowWidth > 1250) {
            setScreen('');
        }
    };

    const handleToggleUserType = () => {
        setUserType(!userType);
    };

    return (
        <body className='bodyLogin'>
            <div className='background'>
                <div className='containerWeb'>
                    {userType ? (
                        <p className='title'>Login de Colaborador</p>
                    ) : (
                        <div>
                            <p className='title'>
                                Faça seu login
                            </p>
                            <div className='title2'>e desfrute de uma solução<div className='title1'> Simples, Segura</div> e <div className='title1'>Exclusiva</div></div>
                        </div>
                    )}
                    <p className='label'>E-mail:</p>
                    <div className='input-password'>
                        <MdPersonOutline className='icon' />
                        <input
                            className='input-container-input'
                            maxLength={40}
                            value={email}
                            placeholder='Insira seu e-mail'
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <p className='label' onClick={getSizes}>
                        Senha:
                    </p>
                    <div className='input-password'>
                        <MdLockOutline className='icon' />
                        <input
                            className='input-container-input'
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder='Insira sua senha'
                            type={showPassword ? 'text' : 'password'}
                        />
                        <div onClick={handleShowPassword} className='buttonEye'>
                            {showPassword ? <RiEyeFill /> : <RiEyeOffFill />}
                        </div>
                    </div>
                    <div className='forgot-password' onClick={handleForgot}>
                        Esqueci minha senha
                    </div>
                    <div className='button-container'>
                        {userType ? (
                            <button className='buttonEntrar' onClick={handleFuncionario}>
                                Entrar como Colaborador
                            </button>
                        ) : (
                            <button className='buttonEntrar' onClick={handleLogin}>
                                Entrar
                            </button>
                        )}
                    </div>
                    <div className='divConta'>
                        <p className='title11'>
                            Ainda não possui conta?</p>{' '}
                        <button className='cadastro' onClick={() => { navigate('/register') }}>
                            Cadastre-se aqui.
                        </button>
                    </div>

                    <button className='toggle-button' onClick={handleToggleUserType}>
                        {userType ? 'Alterar para login Empresarial' : 'Alterar para login de Colaborador'}
                    </button>
                    <p style={{
                        fontSize: '12px',
                        textAlign: 'end',
                    }}>v 1.5.1</p>
                </div>
            </div>
        </body>
    );
};

export default Login;
