import React from "react";
import { GrDocumentUpdate } from "react-icons/gr";
import '../styles/Investidors.css';
import { FaFolderClosed } from "react-icons/fa6";

const DetailsEmissor = ({ selectedEmissor, handleSave, onClose }) => {
    return (
        <div>
            <div className="modal">
                <div className="modal-content">
                    <button className="modalClose" onClick={onClose}>
                        X
                    </button>
                    <h3 className="per1">Detalhes do Emissor Selecionado:</h3>
                    <div className="investidorContainer">
                        <div className="investidorField">
                            <div className="invesP">Razão Social:</div>
                            <div className="invesP1">{selectedEmissor?.razaoSocial}</div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">CNPJ:</div>
                            <div className="invesP1">{selectedEmissor?.cnpj}</div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">Representante:</div>
                            <div className="invesP1">{selectedEmissor?.representante}</div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">Endereço:</div>
                            <div className="invesP1">
                                CEP: {selectedEmissor?.cep},<br />
                                Rua: {selectedEmissor?.rua},<br />
                                Bairro: {selectedEmissor?.bairro},<br />
                                Cidade: {selectedEmissor?.cidade},<br />
                                UF: {selectedEmissor?.estado}
                            </div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">Avalistas:</div>
                            <div className="invesP1">{selectedEmissor?.avalistas.map((avalista, index) => (<div>
                                Avalista {index + 1}
                                . {avalista.aNome}, {avalista.aCpf}
                            </div>))}</div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">Email:</div>
                            <div className="invesP1">{selectedEmissor?.email}</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'start' }}>
                        <button onClick={handleSave} className="newButtonInves">Selecionar Emissor</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailsEmissor;
