import React, { } from "react";
import { Page, Text, View, Document, Image, PDFViewer } from "@react-pdf/renderer";
import { pdfStyle } from "./pdfStyles";
import { chunkArray } from "./TrusteeComGarantia";

export const formatDateString = (inputDate) => {
    const months = [
        "janeiro", "fevereiro", "março", "abril", "maio", "junho",
        "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
    ];

    const year = inputDate.getFullYear();
    const month = months[inputDate.getMonth()];
    const day = inputDate.getDate();

    return `${day} de ${month} de ${year}`;
};

const formatCnpj = (text) => {
    if (text) {
        const cnpj = text.replace(/\D/g, '');
        let formattedCnpj = '';

        for (let i = 0; i < cnpj.length; i++) {
            if (i === 2 || i === 5) {
                formattedCnpj += '.';
            } else if (i === 8) {
                formattedCnpj += '/';
            } else if (i === 12) {
                formattedCnpj += '-';
            }
            formattedCnpj += cnpj[i];
        }

        return formattedCnpj;
    }
};
function parseDateString(str) {
    const meses = [
        "janeiro", "fevereiro", "março", "abril", "maio", "junho",
        "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
    ];

    const partes = str.split(' ');

    if (partes.length !== 5) {
        throw new Error("Formato de data inválido");
    }

    const dia = parseInt(partes[0], 10);
    const mes = meses.indexOf(partes[2]) + 1; // +1 porque o índice do array começa em 0
    const ano = parseInt(partes[4], 10);

    if (isNaN(dia) || isNaN(mes) || isNaN(ano)) {
        throw new Error("Formato de data inválido");
    }

    return new Date(ano, mes - 1, dia); // Mês é base 0, subtrai 1
}
function parseDate(str) {
    const parts = str.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
    return new Date(year, month - 1, day); // Mês é base 0, subtrai 1
}
const TrusteeSemGarantia = ({ dados }) => {
    const formValues = JSON.parse(localStorage.getItem('formValues'));
    const numero = require('numero-por-extenso');
    const emissor = JSON.parse(localStorage.getItem('emissor'));
    const investidor = JSON.parse(localStorage.getItem('investidor'));
    const allInfoArray = JSON.parse(localStorage.getItem('allInfoArray'));

    const sacData = JSON.parse(localStorage.getItem('sacData'));
    const calcData2 = JSON.parse(localStorage.getItem('calcData'));
    let calcData = calcData2.length > 0 ? calcData2 : sacData
    console.log(calcData, 'calcData');
    console.log(sacData, 'sacData');
    const now = new Date();
    const parcelas = allInfoArray[4]

    const linhaDoVencimento = calcData ? calcData[calcData.length - 1] : sacData[sacData.length - 1]
    const linhaPrimeira = calcData ? calcData[0] : sacData[0]
    const dataPrimeira = linhaPrimeira[1] < 10 ? `0${linhaPrimeira[1]}/${linhaPrimeira[2] - 1}/${linhaPrimeira[3]}` : `${linhaPrimeira[1]}/${linhaPrimeira[2] - 1}/${linhaPrimeira[3]}`;
    const dataEmissao = formatDateString(now);
    console.log(dataEmissao, 'dataEmissao');
    const dataDoVencimento = linhaDoVencimento[1] < 10 ? `0${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}` : `${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}`;

    const data1 = parseDateString(dataEmissao);
    console.log(data1, 'data1');
    const data2 = parseDate(dataDoVencimento);
    const diffEmMilissegundos = data2 - data1;
    const umDiaEmMilissegundos = 1000 * 60 * 60 * 24;
    const totalDias = Math.floor(diffEmMilissegundos / umDiaEmMilissegundos);
    console.log(`Total de dias entre as datas: ${totalDias}`);
    console.log('chegou aqui');
    console.log(dataDoVencimento);

    const enderecoDoEmissor = `${emissor.rua}, Nº ${emissor.numero}, ${emissor.complemento}, ${emissor.bairro} - ${emissor.cidade}/${emissor.estado}, CEP:${emissor.cep}`
    const enderecoDoInvestidor = `${investidor.rua}, Nº ${investidor.numero}, ${investidor.complemento} - ${investidor.cidade}/${investidor.estado}, CEP:${investidor.cep}`

    const styles = pdfStyle
    const avalistas = emissor.avalistas || [];
    const chunkedCalcData = chunkArray(calcData, 9);
    let isFirstPage = true;

    function transformarInvestidor(investidor) {
        if (investidor.nome && investidor.nome2 && investidor.email) {
            const investidorTransformado = {
                ...investidor,
                representantes: [
                    { nome: investidor.nome, email: investidor.email }
                ]
            };

            // Se 'nome2' existir, adicione um segundo representante
            if (investidor.nome2) {
                investidorTransformado.representantes.push({
                    nome: investidor.nome2,
                    email: investidor.email
                });
            }

            return investidorTransformado;
        }

        return investidor; // Retorna o objeto original se não houver transformação
    }

    const valorC2 = parseFloat(formValues.taxaEmissao) + parseFloat(formValues.alienacao) + parseFloat(formValues.tac)
     const valorC3 = parseFloat(formValues.taxaEmissao) + parseFloat(formValues.assinaturaDigital)
    console.log(valorC3,'formValues')

    // Use a função para transformar o objeto investidor
    const investidorTransformado = transformarInvestidor(investidor);
    console.log(investidorTransformado);
    return (
        <Document>
            <Page style={styles.page}>
                <View style={[styles.header, { alignItems: 'center' }]}>
                    <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '85%', marginBottom: '10px', height: '35px' }]} />
                </View>
                <View style={styles.section}>
                    <Text style={[styles.textCenter]}>
                        TERMO DE EMISSÃO DA {dados.emissao} EMISSÃO DE NOTAS COMERCIAIS, EM SÉRIE ÚNICA, PARA DISTRIBUIÇÃO PRIVADA COM ESFORÇOS RESTRITOS DA <Text style={styles.textBold}>{emissor.razaoSocial}</Text></Text>
                    <View style={styles.header}>
                        <Text style={[styles.text1, { marginVertical: 80 }]}>entre</Text>
                    </View>
                    <View style={styles.header}>
                        <Text style={[styles.text1, { marginVertical: 20 }]}>{emissor.razaoSocial}{"\n"} como emitente</Text>
                    </View>
                    <View style={styles.header}>
                        <Text style={[styles.text1, { marginVertical: 20 }]}>TRUSTEE DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA{"\n"}
                            como Escriturador
                        </Text>
                    </View>
                    <View style={[styles.header, { marginBottom: '190px' }]}>
                        <Text style={[styles.text1, { marginVertical: 20 }]}>{investidor.razaoSocial}{"\n"}
                            como Titular</Text>
                    </View>
                </View>
                <View style={[styles.footer, { position: 'absolute', bottom: 0 }]}>
                    <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                </View>
            </Page>
            <Page style={styles.page}>
                <View style={[styles.header, { alignItems: 'center' }]}>
                    <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '85%', marginBottom: '0px', height: '35px' }]} />
                </View>
                <View style={styles.section}>
                    <Text style={[styles.textBold, { textAlign: 'center', fontSize: 12, marginVertical: 18 }]}>NOTA COMERCIAL</Text>
                    <Text wrap style={[styles.text1, { textAlign: 'justify', marginHorizontal: 20, fontSize: 12 }]}>
                            <Text style={styles.textBold}>{emissor.razaoSocial}</Text> {'\n'} – empresa de responsabilidade limitada, sem registro de capital aberto na Comissão  de Valores Mobiliários (“CVM”), com sede na {enderecoDoEmissor}, inscrita no Cadastro Nacional da Pessoa Jurídica do Minis&shy;tério da Economia (“CNPJ/ME”) sob o nº {emissor.cnpj}, com endereço eletrônico: {emissor.email}, neste ato representada por seus representantes legais {emissor.representante}, na qualidade de Emitente das Notas Comerciais (conforme definido abaixo);
                    </Text>

                    <Text style={[styles.text1, { textAlign: 'justify', marginHorizontal: 20, fontSize: 12, marginTop: 20 }]}>
                        <Text style={styles.textBold}>TRUSTEE DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA</Text>, inscrita CNPJ/ME nº. 67.030.395/0001-46, Av. Brigadeiro Faria Lima, nº 3477, Itaim Bibi, CEP 04538-132, na cidade e comarca de São Paulo, estado de São Paulo neste ato representada na forma de seu contrato social por seus representantes legais ao final subscritos, (“Escriturador”);
                    </Text>

                    <Text style={[styles.text1, { textAlign: 'justify', marginHorizontal: '20px', fontSize: 12, marginTop: '20px' }]}>
                        <Text style={styles.textBold}>{investidor.razaoSocial}</Text>  {'\n'} – empresa de responsabilidade limitada, sem registro de capital aberto na Comissão de Valores Mobiliários (“CVM”), com sede na {enderecoDoInvestidor}, inscrita no Cadastro Nacional da Pessoa Jurídica do Minis&shy;tério da Economia (“CNPJ/ME”) sob o nº {investidor.cnpj}, com endereço eletrônico: {investidor.representantes[0].email}, neste ato re&shy;presentada por seus representantes legais {investidor.representantes.map((item, index) => (
                            <React.Fragment key={index}>
                                {item.nome}
                                {index < investidor.representantes.length - 1 && '\n'}
                            </React.Fragment>
                        ))},{'\n'}
                         na qualidade de Titular das Notas Comerciais (conforme definido abaixo);
                    </Text>
                    {avalistas.map((avalista, index) => (
                        <Text key={index} style={[styles.text1, { textAlign: 'justify', marginHorizontal: '20px', fontSize: 12, marginTop: '20px' }]}>
                            <Text style={styles.textBold}>AVALISTA</Text>: {avalista.aNome}, inscrito no CPF nº {avalista.aCpf}, na forma de seus atos cons&shy;titutivos, email: {avalista.aEmail}; Na qualidade de Avalista
                        </Text>
                    ))}

                    <Text style={[styles.text1, { textAlign: 'justify', marginHorizontal: '20px', fontSize: 12, marginTop: '20px' }]}>
                        sendo a Emitente, o Escriturador, e o Titular doravante denominados, em conjunto, como “Partes” e, individual e indistintamente, como “Parte”;
                    </Text>
                   



                </View>
                <View style={[styles.footer, { position: 'absolute', bottom: 0 }]}>
                    <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                </View>
            </Page>
            <Page style={styles.page}>
                <View style={[styles.header, { alignItems: 'center' }]}>
                    <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '85%', marginBottom: '2px', height: '35px' }]} />
                </View>
                <Text style={[styles.text1, { textAlign: 'justify', marginHorizontal: '20px', fontSize: 12, marginTop: '20px' }]}>
                        As Partes, na melhor forma de direito, firmam o presente <Text style={{ textDecoration: 'underline' }}>Termo de Emissão da {dados.emissao} Emissão de Notas Comerciais, sem Garantia Real, em Série Única, para Distribuição Privada Com Esforços Restritos</Text>, da {emissor.razaoSocial}
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginHorizontal: '20px', fontSize: 12, marginTop: '20px' }]}>
                        (“Termo de Emissão”, “Notas Comerciais” e “Emissão”, respectivamente), nos termos do artigo
                        45 e seguintes da Lei nº 14.195 de 26 de agosto de 2021, nos seguintes termos e condições:
                    </Text>
                <View style={styles.section}>
                    <Text style={[styles.textBold, { textAlign: 'justify', marginHorizontal: '20px', fontSize: 12, marginTop: '20px' }]}>
                        1.{"   "}AUTORIZAÇÃO
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px', marginBottom: '10px' }]}>
                        1.1{"   "}Os administradores da Emitente estão devidamente autorizados a contrair as obrigações aqui previstas em nome da Emitente e a celebrar o presente Termo de Emissão,
                        nos termos do Parágrafo único, do artigo 46 da Lei nº14.195, de 26 de agosto de 2021 (“Lei 14.195”) e em conformidade com seus Atos Co&shy;nstitutivos e alterações posteriores vigentes nesta data.
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                        1.2{"   "}A Emissão está condicionada, nos termos do artigo 125 da Lei 10.406, de 10 de janeiro de 2002, conforme alterada, à verificação do cumprimento dos seguintes atos (“Condições Precedentes”):
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                        <Text style={styles.textBold}>a.</Text>{"   "}perfeita formalização, pela Emitente, e demais partes signatárias, do Termo Cons&shy;titutivo e do Boletim de Subscrição (conjuntamente, os “Documentos da Operação”), bem como a verificação dos poderes dos representantes dessas partes e eventuais aprovações societárias necessárias à celebração desses documentos;
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                        <Text style={styles.textBold}>b.</Text>{"   "}	recebimento, pela Emitente, do Boletim de Subscrição devidamente formalizado, cujo modelo segue anexo a este Termo Constitutivo no Anexo I.
                    </Text>
                    <Text style={[styles.textBold, { fontSize: 12, marginHorizontal: '20px', marginVertical: '10px' }]}>2.{"   "}REQUISITOS</Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '10px' }]}>
                        2.1{"   "}<Text style={{ textDecoration: 'underline' }}>Registro na CVM</Text> – Por se tratar de oferta privada de valores mobiliários é dispensado o protocolo, registro e arquivamento perante a CVM.</Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                        2.2{"   "}<Text style={{ textDecoration: 'underline' }}>Arquivamento e Publicação dos Documentos Societários </Text>- Nos termos dos Atos Constitutivos da Emitente a presente Emissão está dispensada da realização de Assembleia geral extraordinária ou reunião do conselho de administração. Não sendo, portanto, necessária a apresentação de Ata de Aprovação da Emissão.
                    </Text>
                    {/*<Text style={[styles.textBold, { textAlign: 'justify', marginHorizontal: '20px', fontSize: 12, marginTop: '10px' }]}>
                        3.{"   "}CARACTERÍSTICAS DA EMISSÃO
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '10px' }]}>
                        3.1{"   "}<Text style={{ textDecoration: 'underline' }}>Número da Emissão</Text>
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '60px', marginRight: '20px', fontSize: 12, marginTop: '10px' }]}>
                        3.1.1{"   "}A presente Emissão representa a {dados.emissao} emissão de Notas Co&shy;merciais da Emitente.
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '10px' }]}>
                        3.2{"   "
                        }<Text style={{ textDecoration: 'underline' }}>Valor Total da Emissão
                        </Text>
                    </Text>

                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '10px' }]}>
                        3.2.1{"   "}O valor total da emissão será de {allInfoArray[13]} na data de emissão (conforme definido abaixo) (“Valor Total da Emissão”).
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '10px' }]}>
                        3.3{"   "}<Text style={{ textDecoration: 'underline' }}>Número de Séries
                        </Text>
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '60px', marginRight: '20px', fontSize: 12, marginTop: '10px', marginBottom: '30px' }]}>
                        3.3.1{"   "}A emissão será realizada em série única.
                    </Text>*/}


                </View>
                <View style={[styles.footer, { position: 'absolute', bottom: 0 }]}>
                    <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                </View>
            </Page>
            <Page style={styles.page}>
                <View style={[styles.header, { alignItems: 'center' }]}>
                    <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '85%', height: '35px' }]} />
                </View>
                <Text style={[styles.textBold, { textAlign: 'justify', marginHorizontal: '20px', fontSize: 12, marginTop: '10px' }]}>
                        3.{"   "}CARACTERÍSTICAS DA EMISSÃO
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '10px' }]}>
                        3.1{"   "}<Text style={{ textDecoration: 'underline' }}>Número da Emissão</Text>
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '60px', marginRight: '20px', fontSize: 12, marginTop: '10px' }]}>
                        3.1.1{"   "}A presente Emissão representa a {dados.emissao} emissão de Notas Co&shy;merciais da Emitente.
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '10px' }]}>
                        3.2{"   "
                        }<Text style={{ textDecoration: 'underline' }}>Valor Total da Emissão
                        </Text>
                    </Text>

                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '10px' }]}>
                        3.2.1{"   "}O valor total da emissão será de {allInfoArray[13]} na data de emissão (conforme definido abaixo) (“Valor Total da Emissão”).
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '10px' }]}>
                        3.3{"   "}<Text style={{ textDecoration: 'underline' }}>Número de Séries
                        </Text>
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '60px', marginRight: '20px', fontSize: 12, marginTop: '10px', marginBottom: '30px' }]}>
                        3.3.1{"   "}A emissão será realizada em série única.
                    </Text>
                <View style={styles.section}>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12 }]}>
                        3.4{"   "}<Text style={{ textDecoration: 'underline' }}>Quantidade de Notas Comerciais</Text>
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                        3.4.1{"   "}Serão emitidas 1 (um) Notas Comerciais em série única.
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                        3.5{"   "}<Text style={{ textDecoration: 'underline' }}>Valor Nominal Unitário</Text>
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                        3.6{"   "}O valor nominal unitário da Nota Comercial será de {allInfoArray[13]} na data de emissão (“valor nominal unitário”).
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginHorizontal: '20px', fontSize: 12, marginTop: '20px' }]}>
                        3.7{"   "}<Text style={{ textDecoration: 'underline' }}>Destinação de Recursos{'\n'}
                        </Text>
                        <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                            Os recursos líquidos captados por meio da emissão serão destinados a título de capital de giro da Emitente.
                        </Text>
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px', marginBottom: '20px' }]}>3.8{"   "}
                        <Text style={{ textDecoration: 'underline' }}>Escriturador
                        </Text>
                    </Text>
                    <Text style={[styles.text1, { fontSize: 12, marginLeft: '40px', marginRight: '20px', marginBottom: '20px' }]}>3.8.1.{"   "}
                        <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12 }]}>
                            O Escriturador da presente emissão é a TRUSTEE DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA, inscrita CNPJ/ME nº. 67.030.395/0001-46, Av. Brigadeiro Faria Lima, nº 3477, Itaim Bibi, CEP 04538-132, na cidade e comarca de São Paulo, estado de São Paulo neste ato representada na forma de seu contrato social por seus representantes legais ao final subscritos, (“Escriturador”);. O Escriturador será responsável por realizar a escrituração das Notas Comerciais, entre outras responsa&shy;bilidades eventualmente definidas nas normas aplicáveis editadas pela Comissão de Valores Mobiliários, Conselho Monetário Nacional e Banco Central do Brasil.
                        </Text></Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginBottom: '10px', marginLeft: '40px', marginRight: '20px', }]}>3.8.2.
                        <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', marginBottom: '20px', fontSize: 12 }]}>{"   "}As definições acima incluem quaisquer outras instituições que venham a suce&shy;der o Agente de liquidação e/ou o Escriturador na prestação dos serviços relativos às Notas Comerciais.</Text></Text>
                   {/* <Text style={[styles.textBold, { fontSize: 12, marginVertical: '0px', marginHorizontal: '20px', marginTop: '20px', textAlign: 'justify' }]}>4.{"   "}CARACTERÍSTICAS GERAIS DAS NOTAS COMERCIAIS
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>4.1{"   "}<Text style={{ textDecoration: 'underline' }}>Local de Emissão: </Text>para todos os fins e efeitos legais, o local de emissão das Notas Comerciais será {emissor.cidade}, {emissor.estado}.
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>4.2{"   "}<Text style={{ textDecoration: 'underline' }}>Data de Emissão: </Text>para todos os fins e efeitos legais, a data de emissão das Notas Comerciais será o dia {dataEmissao}.
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>4.3{"   "}<Text style={{ textDecoration: 'underline' }}>Data de Início da Rentabilidade: </Text>para todos os fins e efeitos legais, a data de início da rentabilidade [e aplicação da atualização monetária [prefixadas ou indexadas na taxa de Depósito Interfinanceiro (DI), na taxa Selic ou na taxa Referencial (TR)] (conforme abaixo definido)] será a data da primeira subscrição e integralização de Notas Comerciais (“Data de Início da Rentabilidade” e “Data da Primeira Integralização”, respectivamente).
                    </Text>*/}
                </View>
                <View style={[styles.footer, { position: 'absolute', bottom: 0 }]}>
                    <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                </View>
            </Page>
            <Page style={styles.page}>
                <View style={[styles.header, { alignItems: 'center' }]}>
                    <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '85%', marginBottom: '2px', height: '35px' }]} />
                </View>
                <Text style={[styles.textBold, { fontSize: 12, marginVertical: '0px', marginHorizontal: '20px', marginTop: '20px', textAlign: 'justify' }]}>4.{"   "}CARACTERÍSTICAS GERAIS DAS NOTAS COMERCIAIS
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>4.1{"   "}<Text style={{ textDecoration: 'underline' }}>Local de Emissão: </Text>para todos os fins e efeitos legais, o local de emissão das Notas Comerciais será {emissor.cidade}, {emissor.estado}.
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>4.2{"   "}<Text style={{ textDecoration: 'underline' }}>Data de Emissão: </Text>para todos os fins e efeitos legais, a data de emissão das Notas Comerciais será o dia {dataEmissao}.
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>4.3{"   "}<Text style={{ textDecoration: 'underline' }}>Data de Início da Rentabilidade: </Text>para todos os fins e efeitos legais, a data de início da rentabilidade [e aplicação da atualização monetária [prefixadas ou indexadas na taxa de Depósito Interfinanceiro (DI), na taxa Selic ou na taxa Referencial (TR)] (conforme abaixo definido)] será a data da primeira subscrição e integralização de Notas Comerciais (“Data de Início da Rentabilidade” e “Data da Primeira Integralização”, respectivamente).
                    </Text>
                <View style={styles.container}>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>4.4{"   "}<Text style={{ textDecoration: 'underline' }}>Forma, Tipo e Comprovação de Titularidade: </Text>as Notas Comerciais serão emitidas sob a forma escritural, sem emissão de cautelas ou certificados, sendo que, para todos os fins de
                        direito, a titularidade das Notas Comerciais será comprovada pelo extrato emitido pelo Escriturador.

                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>4.5{"   "}<Text style={{ textDecoration: 'underline' }}>Prazo e Data de Vencimento: </Text>observado o disposto neste Termo de Emissão, as Notas Comerciais da Série única terão prazo de {totalDias} dias, vencendo-se, portanto, em {dataDoVencimento}.
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>4.6{"   "}<Text style={{ textDecoration: 'underline' }}>Juros remuneratórios e Plano de Pagamento:</Text>
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>4.6.1{"   "}
                        Juros Remuneratórios: os juros remuneratórios Notas Comerciais da série única serão de {allInfoArray[10]} a.m. ({allInfoArray[10]} por cento ao mês) base exponencial 360 (trezentos e sessenta) dias corridos, e {allInfoArray[20]} a.a. ({numero.porExtenso(allInfoArray[20], numero.estilo.porcentagem)} ao ano) sendo o {parcelas > 1 ? 'pagamento final' : 'pagamento único'} em {dataDoVencimento}.
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>4.6.2{"   "}
                        Plano de Pagamento: no Plano de Pagamento será detalhado todo o fluxo de parcelas para pagamento das Notas Comerciais, contendo datas de vencimentos das parcelas, juros, saldo devedor, apresentado em Anexo II ao presente instrumento.
                    </Text>
                  {/*  <Text style={[styles.text1, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>4.6.3{"   "}
                        Tabela de amortização: O plano de pagamentos apresentado no Anexo II foi calculado utilizando a metodologia PRICE, com a seguinte fórmula:
                    </Text>
                    <Image src="https://i.ibb.co/g6PfrVT/Imagem-do-Whats-App-de-2023-09-15-s-15-27-37.jpg" style={{ width: '50%', marginBottom: '0px', height: '55px', marginTop: '20px', alignSelf: 'center' }} />
                    <Text style={[styles.text1, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>

                        {'\n'}
                        Onde:{'\n'}
                        PMT é o valor final da parcela;{'\n'}
                        PV é o valor inicial da dívida{'\n'} i é a taxa de juros nominal{'\n'}
                        n é o número de períodos{'\n'}
                    </Text>
                    <Text style={[styles.text1, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px', marginBottom: '40px' }]}>4.7{"   "}
                        <Text style={{ textDecoration: 'underline' }}>Local do Pagamento: </Text>
                        São Paulo/SP, em conta do titular da Nota Comercial ora identificada: número da conta {emissor.conta}, na Agência {emissor.agency}, na instituição {emissor.banco} e número do banco {emissor.bankNumber}.
                </Text>*/}

                </View>
                <View style={[styles.footer, { position: 'absolute', bottom: 0 }]}>
                    <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                </View>
            </Page>
            <Page style={styles.page}>
                <View style={[styles.header, { alignItems: 'center' }]}>
                    <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '85%', marginBottom: '2px', height: '35px' }]} />
                </View>
                <Text style={[styles.text1, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>4.6.3{"   "}
                        Tabela de amortização: O plano de pagamentos apresentado no Anexo II foi calculado utilizando a metodologia PRICE, com a seguinte fórmula:
                    </Text>
                    <Image src="https://i.ibb.co/g6PfrVT/Imagem-do-Whats-App-de-2023-09-15-s-15-27-37.jpg" style={{ width: '50%', marginBottom: '0px', height: '55px', marginTop: '20px', alignSelf: 'center' }} />
                    <Text style={[styles.text1, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>

                        {'\n'}
                        Onde:{'\n'}
                        PMT é o valor final da parcela;{'\n'}
                        PV é o valor inicial da dívida{'\n'} i é a taxa de juros nominal{'\n'}
                        n é o número de períodos{'\n'}
                    </Text>
                    <Text style={[styles.text1, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px', marginBottom: '40px' }]}>4.7{"   "}
                        <Text style={{ textDecoration: 'underline' }}>Local do Pagamento: </Text>
                        São Paulo/SP, em conta do emissor da Nota Comercial ora identificada: número da conta {emissor.conta}, na Agência {emissor.agency}, na instituição {emissor.banco} e número do banco {emissor.bankNumber}.
                </Text>
                <View style={styles.section}>
                    <Text style={[styles.text1, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px', marginBottom: '20px' }]}>4.8{"   "}
                        <Text style={{ textDecoration: 'underline' }}>Preço de Subscrição e Forma de Integralização:
                        </Text>
                    </Text>
                    <Text style={[styles.text1, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px', textAlign: 'justify' }]}>4.8.1. <Text>
                        As Notas Comerciais serão subscritas, nos termos do Boletim de Subscrição, ora em anexo (Anexo I) e integralizadas à vista, em moeda corrente nacional, no ato da subscrição, pelo seu valor nominal unitário acrescido da remuneração, calculada pro rata temporis a partir da data de início da rentabilidade, de acordo com as normas de liquidação aplicáveis à B3. Caso seja possível a integralização em mais de uma data, a Nota Comercial que venha ser integralizada em data diversa e posterior à primeira Data de Integralização deverá ser integralizada considerando o seu valor nominal unitário acrescido da remuneração, calculada pro rata temporis desde a data de início da rentabilidade (inclusive) até a data de sua efetiva integralização (exclusive).
                    </Text>

                    </Text>
                    {/*<Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>4.8.2. <Text>
                        As Notas Comerciais poderão ser subscritas com ágio ou deságio,
                        a ser definido, de comum acordo entre a Emitente e as instituições intermediárias,
                        no ato de subscrição das Notas Comerciais, observado que referido ágio ou deságio
                        deverá ser aplicado de forma igualitária à totalidade dos titulares das Notas
                        Comer&shy;ciais, se houver.
                    </Text>
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>
                        4.9.{"   "}
                        <Text style={{ textDecoration: 'underline' }}>
                            Atualização Monetária das Notas Comerciais
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>
                        4.9.1{"   "}
                        <Text style={{ textDecoration: 'underline' }}>
                            Indexador:
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>
                        <Text style={styles.textBold}>Para fins de correção monetária, o indexador incidirá sobre o saldo devedor, nos termos da Plano de Pagamento (Planilha de Cálculo) constante do Anexo II.{'\n'}
                            {dados.indexador !== 'Pré-Fixado' ? '(x)Pós-fixado' : ''}{dados.prcntgm ? ` ${dados.prcntgm}% do ` : ''}</Text>{dados.indexador ? dados.indexador : 'Pré-Fixado'} da taxa média dos Depósitos Interfinanceiros DI de um dia, “over extra grupo”, expressa na forma percentual ao ano calculada e divulgada pela B3 S/A BRASIL, BOLSA, BALCÃO (“Taxa DI” e “B3”, respectivamente);{'\n'}

                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>
                        4.9.2.{"   "}
                        <Text>
                            Em caso de não haver nenhuma marcação de indexador no item 4.9.1 o valor nominal unitário
                            das Notas Comerciais da série única não será atualizado monetariamente.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px', marginBottom: '60px' }]}>
                        4.9.3.{"   "}
                        <Text style={{ textDecoration: 'underline' }}>
                            Taxa Substitutiva sem deliberação em assembleia
                        </Text>
            </Text>*/}

                </View>
                <View style={[styles.footer, { position: 'absolute', bottom: 0 }]}>
                    <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                </View>
            </Page>
            <Page style={styles.page}>
                <View style={[styles.header, { alignItems: 'center' }]}>
                    <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '85%', marginBottom: '2px', height: '35px' }]} />
                </View>
                <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>4.8.2. <Text>
                        As Notas Comerciais poderão ser subscritas com ágio ou deságio,
                        a ser definido, de comum acordo entre a Emitente e as instituições intermediárias,
                        no ato de subscrição das Notas Comerciais, observado que referido ágio ou deságio
                        deverá ser aplicado de forma igualitária à totalidade dos titulares das Notas
                        Comer&shy;ciais, se houver.
                    </Text>
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>
                        4.9.{"   "}
                        <Text style={{ textDecoration: 'underline' }}>
                            Atualização Monetária das Notas Comerciais
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>
                        4.9.1{"   "}
                        <Text style={{ textDecoration: 'underline' }}>
                            Indexador:
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>
                        <Text style={styles.textBold}>Para fins de correção monetária, o indexador incidirá sobre o saldo devedor, nos termos da Plano de Pagamento (Planilha de Cálculo) constante do Anexo II.{'\n'}
                            {dados.indexador !== 'Pré-Fixado' ? '(x)Pós-fixado' : ''}{dados.prcntgm ? ` ${dados.prcntgm}% do ` : ''}</Text>{dados.indexador ? dados.indexador : 'Pré-Fixado'} da taxa média dos Depósitos Interfinanceiros DI de um dia, “over extra grupo”, expressa na forma percentual ao ano calculada e divulgada pela B3 S/A BRASIL, BOLSA, BALCÃO (“Taxa DI” e “B3”, respectivamente);{'\n'}

                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>
                        4.9.2.{"   "}
                        <Text>
                            Em caso de não haver nenhuma marcação de indexador no item 4.9.1 o valor nominal unitário
                            das Notas Comerciais da série única não será atualizado monetariamente.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px', marginBottom: '60px' }]}>
                        4.9.3.{"   "}
                        <Text style={{ textDecoration: 'underline' }}>
                            Taxa Substitutiva sem deliberação em assembleia
                        </Text>
                    </Text>
                <View style={styles.section}>
                    <Text style={[styles.textJustify,
                    {
                        fontSize: 12, marginVertical: '0px',
                        marginLeft: '60px', marginRight: '20px',
                    }]}>
                        4.9.3.1{"   "}
                        <Text wrap>
                             Na ausência de apuração e/ou divulgação do DI
                            por prazo superior a 30 (trinta) dias contados da
                            data esperada para sua apuração e/ou divulgação
                            ( “Período de Ausência do DI” )  ou, ainda, na hipótese
                            de  extinção  ou  inaplicabilidade por disposição legal
                            ou determinação judicial, o DI deverá ser substituído
                            pelo seu substituto legal ou, no caso de inexistir
                            substituto legal para o DI, deverá ser considerado
                            o índice que tenha substancialmente os mesmos atributos
                            do DI, novo parâmetro que deverá refletir parâmetros
                            utilizados em operações similares existentes à época a
                            ser evidenciado pela Emitente (“Taxa Substitutiva das
                            Notas Comerciais da Série única).
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify,
                    {
                        fontSize: 12, marginVertical: '0px',
                        marginLeft: '60px', marginRight: '20px', marginTop: '20px'
                    }]}>
                        4.9.3.2{"   "}
                        <Text>
                            Caso o DI venha a ser divulgado antes da definição da Taxa Substitutiva
                            das Notas Comerciais da série única, o DI a partir de sua divulgação
                            voltará a ser utilizado para o cálculo do valor nominal unitário atualizado
                            das Notas Comerciais da série única desde o dia de sua indisponibilidade.
                        </Text>
                    </Text>
                   {/* <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '20px', marginRight: '20px', marginTop: '20px' }]}>
                        5.{"   "}
                        <Text style={styles.textBold}>
                            VENCIMENTO ANTECIPADO
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        5.1.{"   "}
                        <Text>
                            Observado o disposto nesta cláusula, serão consideradas antecipadamente vencidas as obrigações decorrentes das Notas Comerciais e exigível o imediato pagamento pela Emitente do Valor Nominal Unitário, acrescido da remuneração, calculada pro rata temporis, e dos encargos
                            moratórios e multas, se houver, incidentes até a data do seu efetivo pagamento, na ocorrência de quaisquer das situações previstas nesta cláusula, respeitados os respectivos prazos de cura (cada um desses eventos, um “Evento de Vencimento Antecipado”):
                        </Text>
                </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        5.1.1.{"   "}
                        <Text>
                            Observados os eventuais prazos de cura aplicáveis, a ocorrência de quaisquer dos eventos indicados nesta cláusula 5.1.1 acarretará o vencimento antecipado automático das Notas Comerciais, independentemente de qualquer aviso extrajudicial, interpelação judicial, notificação prévia à Emitente ou consulta ao Ti&shy;tular das Notas Comerciais, exceto se previsto de modo diverso, abaixo (cada um desses eventos, um “Evento de Vencimento Antecipado Automático”):
                        </Text>
                </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        {"a)   "}
                        <Text>
                            Se ocorrer inadimplemento de qualquer obrigação assumida pela Emitente em consonância com as cláusulas e condições aqui estabelecidas, principalmente no que tange ao pagamento das parcelas devidas em decorrências desta Emissão;</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        {"b)   "}
                        <Text>
                            Se ocorrer inadimplemento de qualquer obrigação assumida pela Emitente e/ou quaisquer sociedades direta ou indiretamente ligadas, coligadas, controladoras ou controladas pela Emitente, inclusive no exterior, de suas obrigações decorrentes de outros contratos, empréstimos ou descontos celebrados com o Titular das Notas Comerciais e/ou quaisquer sociedades, direta ou indiretamente, ligadas, coligadas, controladoras ou controladas pelo Titular da Nota Comercial ou seu cessionário, e/ou com terceiros, e/ou rescisão ou decla&shy;ração de vencimento antecipado dos respectivos documentos, por culpa da Emitente e/ou de quaisquer de suas afiliadas;
                        </Text>
                </Text>*/}
                </View>
                <View style={[styles.footer, { position: 'absolute', bottom: 0 }]}>
                    <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                </View>
            </Page>
            <Page style={styles.page}>
                <View style={[styles.header, { alignItems: 'center' }]}>
                    <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '85%', marginBottom: '2px', height: '35px' }]} />
                </View>
                <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '20px', marginRight: '20px', marginTop: '20px' }]}>
                        5.{"   "}
                        <Text style={styles.textBold}>
                            VENCIMENTO ANTECIPADO
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        5.1.{"   "}
                        <Text>
                            Observado o disposto nesta cláusula, serão consideradas antecipadamente vencidas as obrigações decorrentes das Notas Comerciais e exigível o imediato pagamento pela Emitente do Valor Nominal Unitário, acrescido da remuneração, calculada pro rata temporis, e dos encargos
                            moratórios e multas, se houver, incidentes até a data do seu efetivo pagamento, na ocorrência de quaisquer das situações previstas nesta cláusula, respeitados os respectivos prazos de cura (cada um desses eventos, um “Evento de Vencimento Antecipado”):
                        </Text>
                </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        5.1.1.{"   "}
                        <Text>
                            Observados os eventuais prazos de cura aplicáveis, a ocorrência de quaisquer dos eventos indicados nesta cláusula 5.1.1 acarretará o vencimento antecipado automático das Notas Comerciais, independentemente de qualquer aviso extrajudicial, interpelação judicial, notificação prévia à Emitente ou consulta ao Ti&shy;tular das Notas Comerciais, exceto se previsto de modo diverso, abaixo (cada um desses eventos, um “Evento de Vencimento Antecipado Automático”):
                        </Text>
                </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        {"a)   "}
                        <Text>
                            Se ocorrer inadimplemento de qualquer obrigação assumida pela Emitente em consonância com as cláusulas e condições aqui estabelecidas, principalmente no que tange ao pagamento das parcelas devidas em decorrências desta Emissão;</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        {"b)   "}
                        <Text>
                            Se ocorrer inadimplemento de qualquer obrigação assumida pela Emitente e/ou quaisquer sociedades direta ou indiretamente ligadas, coligadas, controladoras ou controladas pela Emitente, inclusive no exterior, de suas obrigações decorrentes de outros contratos, empréstimos ou descontos celebrados com o Titular das Notas Comerciais e/ou quaisquer sociedades, direta ou indiretamente, ligadas, coligadas, controladoras ou controladas pelo Titular da Nota Comercial ou seu cessionário, e/ou com terceiros, e/ou rescisão ou decla&shy;ração de vencimento antecipado dos respectivos documentos, por culpa da Emitente e/ou de quaisquer de suas afiliadas;
                        </Text>
                </Text>
                <View style={styles.section}>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        {"c)   "}
                        <Text>
                            Se a Emitente for inscrita no Cadastro de Emitentes de Cheques sem Fundo – CCF, ou, ainda, constem informações negativas a seu respeito no Sistema de Informações de Crédito do Banco Central, que, a critério do Titular das Notas Comerciais, possam afetar a sua capacidade de cumprir as obrigações assumidas na Notas Comerciais;
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        {"d)   "}
                        <Text>
                            Se a Emitente se tornar insolvente, requerer ou tiver falência, insolvência civil, recuperação judicial ou extrajudicial decretada, sofrer intervenções, regime de administração especial temporária, ou liquidação judicial ou extrajudicial;
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        {"e)   "}
                        <Text>
                            Se for comprovada a falsidade de qualquer declaração, informação ou documento que houver sido respectivamente firmada, prestada ou entregue pela Emitente que se fossem do conhecimento do Titular de Notas Comerciais poderiam alterar o julgamento a respeito da subscrição e integralização das Notas Comerciais;</Text>
                    </Text>
                   {/* <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        {"f)   "}
                        <Text>
                            Se a Emitente sofrer qualquer medida judicial ou extrajudicial que por qualquer forma, possa afetar negativamente os créditos do empréstimo e/ou as garantias conferidas ao Titular da Nota Comercial;</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        {"g)   "}
                        <Text>
                            Se a garantia da Nota Comercial, ora constituída e/ou que venha a ser eventualmente convencionada, por qualquer fato atinente ao seu objeto ou prestador se tornar inábil, imprópria, ou insuficiente para assegurar o pagamento da dívida, e desde que não seja substituída, ou complementada, quando solicitada por escrito pelo Titular ou a quem este vier a indicar;</Text>
                </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        {"h)   "}
                        <Text>
                            Se a Emitente realizar qualquer operação de mútuo (como mutuante) para qualquer empresa de seu grupo econômico ou terceiros, exceto se com o prévio e expresso consentimento do Titular das Notas Comerciais;</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        {"i)   "}
                        <Text>
                            Se, sem o expresso consentimento do Titular das Notas Comerciais ocorrer a transferência a terceiros dos direitos e obrigações da Emitente, previstos neste Termo Constitutivo;</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        {"j)   "}
                        <Text>
                            Se, sem o expresso consentimento do Titular das Notas Comerciais ocorrer alienação, cessão, doação ou transferência, por qualquer meio, de bens, ativos ou direitos de propriedade da Emitente, que, no entendimento do Titular, possam levar ao descumprimento das obrigações previstas neste Termo de Emissão;</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        {"k)   "}
                        <Text>
                            Se, sem o expresso consentimento do Titular das Notas Comerciais, a Emitente tiver total ou parcialmente, o seu controle acionário, direto ou indireto, cedido, transferido ou por qualquer outra forma alienado ou modificado;</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        {"l)   "}
                        <Text>
                            Se ocorrer mudança ou alteração do objeto social da Emitente, de forma a alterar as atividades principais ou a agregar às suas atividades novos negócios que possam representar desvios em relação às atividades atualmente desenvolvidas;</Text>
                </Text>*/}
                </View>
                <View style={[styles.footer, { position: 'absolute', bottom: 0 }]}>
                    <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                </View>
            </Page>
            <Page style={styles.page}>
                <View style={[styles.header, { alignItems: 'center' }]}>
                    <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '85%', marginBottom: '2px', height: '35px' }]} />
                </View>
                <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        {"f)   "}
                        <Text>
                            Se a Emitente sofrer qualquer medida judicial ou extrajudicial que por qualquer forma, possa afetar negativamente os créditos do empréstimo e/ou as garantias conferidas ao Titular da Nota Comercial;</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        {"g)   "}
                        <Text>
                            Se a garantia da Nota Comercial, ora constituída e/ou que venha a ser eventualmente convencionada, por qualquer fato atinente ao seu objeto ou prestador se tornar inábil, imprópria, ou insuficiente para assegurar o pagamento da dívida, e desde que não seja substituída, ou complementada, quando solicitada por escrito pelo Titular ou a quem este vier a indicar;</Text>
                </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        {"h)   "}
                        <Text>
                            Se a Emitente realizar qualquer operação de mútuo (como mutuante) para qualquer empresa de seu grupo econômico ou terceiros, exceto se com o prévio e expresso consentimento do Titular das Notas Comerciais;</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        {"i)   "}
                        <Text>
                            Se, sem o expresso consentimento do Titular das Notas Comerciais ocorrer   a      transferência a terceiros dos direitos e obrigações da Emitente, previstos neste Termo Constitutivo;</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        {"j)   "}
                        <Text wrap align="justify">
                            Se, sem o expresso consentimento do Titular das Notas Comerciais ocorrer alienação, cessão, doação ou transferência, por qualquer meio, de bens, ativos ou direitos de propriedade da Emitente, que, no entendimento do Titular, possam levar ao descumprimento das obrigações previstas neste Termo de Emissão;</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        {"k)   "}
                        <Text>
                            Se, sem o expresso consentimento do Titular das Notas Comerciais, a Emitente tiver total ou parcialmente, o seu controle acionário, direto ou indireto, cedido, transferido ou por qualquer outra forma alienado ou modificado;</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '18px'
                    }]}>
                        {"l)   "}
                        <Text>
                            Se ocorrer mudança ou alteração do objeto social da Emitente, de forma a alterar as atividades principais ou a agregar às suas atividades novos negócios que possam representar desvios em relação às atividades atualmente desenvolvidas;</Text>
                </Text>
                <View style={styles.section}>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '18px'
                    }]}>
                        {"m)   "}
                        <Text>
                            Se a Emitente sofrer cisão, fusão, incorporação ou qualquer outro tipo de reorganização societária, que possam levar ao descumprimento de obrigações previstas no Termo de Emissão;</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '18px'
                    }]}>
                        {"n)   "}
                        <Text>
                            Se a Emitente sofrer qualquer (quaisquer) medida(s) judicial(ais) ou extrajudicial(ais) que por qualquer forma, possa(m) afetar negativamente os créditos e/ou as garantias conferidas ao Titular da Notas Comerciais.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '18px'
                    }]}>
                        5.2.{"   "}
                        <Text>
                            No caso de falta de pagamento de qualquer parcela(s) na(s) data(s) de seu(s) respectivo(s) vencimento(s), o Titular ou a quem este vier a indicar, poderá, por mera liberdade e sem que tal situação caracterize novação ou alteração das condições estabelecidas neste instrumento – optar pela cobrança somente da(s) parcela(s) devida(s) em aberto, comprometendo-se a Emitente, em contrapartida, a liquidá-la(s) imediatamente quando instado(s) para tal, sob pena de ultimar-se o vencimento antecipado de toda a dívida;
                        </Text>
                    </Text>
                  {/*  <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        5.2.1{"   "}
                        <Text>
                            Em caso do vencimento antecipado das obrigações decorrentes das Notas Comerciais, será apresentado à Emitente notificação contendo o saldo devedor final, quando restará a Emitente obrigada a efetuar o pagamento da totalidade das Notas Comerciais pelo Valor Nominal Unitário, acrescido da Remuneração, calculada pro rata temporis, desde a Data de Início da Rentabilidade, até a data do efetivo resgate, sem prejuízo do pagamento dos Encargos Moratórios, quando for o caso, e de quaisquer outros valores eventualmente devidos pela Emitente nos termos deste Termo de Emissão, incluindo eventuais despesas vencidas e não pagas, no dia útil imediatamente subsequente ao recebimento de referida notificação, sob pena de ser considerado em mora, independentemente de qualquer aviso ou notificação judicial ou extrajudicial, nos termos previstos nesta Cláusula, sendo certo que tal pagamento é devido pela Emitente desde a data da declaração do vencimento antecipado, podendo o Titular das Notas Comerciais adotar todas as medidas necessárias para a satisfação do seu crédito.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        5.2.2{"   "}
                        <Text>
                            Caso o pagamento da totalidade das Notas Comerciais previsto na Cláusula 5.2.1 acima
                            seja realizado por meio da B3, a Emitente deverá comunicar a B3, por meio de correspondência sobre o tal pagamento, com, no mínimo, 3 (três) Dias Úteis de antecedência da data estipulada para a sua realização.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        6.{"   "}
                        <Text style={styles.textBold}>
                            ATRASO NO PAGAMENTO E ENCARGOS MORATÓRIOS
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px', marginBottom: '40px'
                    }]}>
                        6.1{"   "}
                        <Text>
                            Na hipótese de inadimplemento ou mora dos valores devidos no âmbito deste Termo Constitutivo, a Emitente estará obrigada a pagar ao titular, cumulativamente, além da quantia correspondente à dívida em aberto, os seguintes encargos:
                        </Text>
                </Text>*/}
                </View>
                <View style={[styles.footer, { position: 'absolute', bottom: 0 }]}>
                    <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                </View>
            </Page>
            <Page style={styles.page}>
                <View style={[styles.header, { alignItems: 'center' }]}>
                    <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '85%', marginBottom: '2px', height: '35px' }]} />
                </View>
                <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        5.2.1{"   "}
                        <Text>
                             Em caso do vencimento antecipado das obrigações decorrentes das Notas Comerciais, será apresentado à Emitente notificação contendo o saldo devedor final,  quando restará a  Emitente  obrigada a efetuar o pagamento da totalidade das Notas Comerciais pelo Valor Nominal Unitário,   acrescido   da       Remuneração, calculada pro rata temporis, desde a Data de Início da Rentabilidade, até a data do efetivo resgate, sem prejuízo do pagamento dos Encargos Moratórios, quando for o caso, e de quaisquer outros valores eventualmente devidos pela Emitente nos termos deste Termo de Emissão, incluindo eventuais despesas vencidas e não pagas, no dia útil imediatamente subsequente ao recebimento de referida notificação, sob pena de ser considerado em mora, independentemente de qualquer aviso ou notificação judicial ou extrajudicial, nos termos previstos nesta Cláusula, sendo certo que tal pagamento é devido pela Emitente desde a data da declaração do vencimento antecipado, podendo o Titular das Notas Comerciais adotar todas as medidas necessárias para a satisfação do seu crédito.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        5.2.2{"   "}
                        <Text>
                            Caso o pagamento da totalidade das Notas Comerciais previsto na Cláusula 5.2.1 acima
                            seja realizado por meio da B3, a Emitente deverá comunicar a B3, por meio de correspondência sobre o tal pagamento, com, no mínimo, 3 (três) Dias Úteis de antecedência da data estipulada para a sua realização.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        6.{"   "}
                        <Text style={styles.textBold}>
                            ATRASO NO PAGAMENTO E ENCARGOS MORATÓRIOS
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px', marginBottom: '40px'
                    }]}>
                        6.1{"   "}
                        <Text>
                            Na hipótese de inadimplemento ou mora dos valores devidos no âmbito deste Termo Constitutivo, a Emitente estará obrigada a pagar ao titular, cumulativamente, além da quantia correspondente à dívida em aberto, os seguintes encargos:
                        </Text>
                </Text>
                <View style={styles.section}>

                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        6.1.1{"   "}
                        <Text>
                            Juros remuneratórios nos mesmos percentuais das taxas constantes neste Termo Constitutivo, calculados a partir do vencimento das parcelas em aberto até a data do efetivo pagamento;
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        6.1.2{"   "}
                        <Text>
                            Juros de mora à razão de 1% a.m. (um por cento ao mês), calculados a partir do vencimento das parcelas em aberto até a data do efetivo pagamento pela Emitente;
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        6.1.3{"   "}
                        <Text>
                            Multa contratual, de natureza não compensatória, de 2% (dois por cento) incidente sobre o montante total atualizado (incluindo juros remuneratórios e juros de mora) do valor devido e não pago; e
                        </Text>
                    </Text>
                   {/* <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        6.1.4{"   "}
                        <Text>
                            Na hipótese de o Titular vir a ser compelido a recorrer a meios administrativos ou judiciais para receber os valores devidos no âmbito deste Termo Constitutivo, as despesas de cobrança administrativa, limitadas a 10% (dez por cento) sobre o valor do saldo devedor deste Termo Constitutivo e, havendo procedimento judicial, custas processuais e honorários advocatícios, estes fixados judicialmente.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        7.{"   "}
                        <Text style={styles.textBold}>
                            DECLARAÇÕES
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        7.1{"   "}
                        <Text>
                            As Partes signatárias declaram e garantem que:
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        7.1.1{"   "}
                        <Text>
                            Possuem plena capacidade e legitimidade para celebrar o presente Termo Cons&shy;titutivo, realizar todas as operações e cumprir todas as obrigações assumidas tendo tomado todas as medidas de natureza societária, conforme aplicável, e outras eventualmente necessárias para autorizar a sua celebração, implementação e cumprimento de todas as obrigações constituídas;
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        7.1.2{"   "}
                        <Text>
                            A celebração deste Termo Constitutivo e o cumprimento das obrigações nele dispostos: (a) não violam qualquer disposição contida nos seus documentos societários, conforme aplicável; (b) não violam qualquer lei, regulamento, decisão judicial, admini&shy;strativa ou arbitral, aos quais a respectiva parte esteja vinculada; (c) não exigem qualquer consentimento ação ou autorização, prévia ou posterior, de terceiros;
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        7.1.3{"   "}
                        <Text>
                            Este Termo Constitutivo é validamente celebrado e constitui obrigação legal, válida, vinculante e exequível, de acordo com os seus termos;
                        </Text>
                    </Text>


                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        7.1.4{"   "}
                        <Text>
                            Estão aptas a cumprir as obrigações ora previstas neste Termo Constitutivo e agirão em relação às mesmas de boa-fé e com lealdade;

                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        7.1.5{"   "}
                        <Text>
                            Não se encontram em estado de necessidade ou sob coação para celebrar este Termo Constitutivo e/ou quaisquer contratos e compromissos a ele relacionados e
                            acessórios;
                        </Text>
                </Text>*/}
                </View>
                <View style={[styles.footer, { position: 'absolute', bottom: 0 }]}>
                    <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                </View>
            </Page>
            <Page style={styles.page}>
                <View style={[styles.header, { alignItems: 'center' }]}>
                    <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '85%', marginBottom: '2px', height: '35px' }]} />
                </View>
                <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        6.1.4{"   "}
                        <Text>
                            Na hipótese de o Titular vir a ser compelido a recorrer a meios administrativos ou judiciais para receber os valores devidos no âmbito deste Termo Constitutivo, as despesas de cobrança administrativa, limitadas a 10% (dez por cento) sobre o valor do saldo devedor deste Termo Constitutivo e, havendo procedimento judicial, custas processuais e honorários advocatícios, estes fixados judicialmente.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        7.{"   "}
                        <Text style={styles.textBold}>
                            DECLARAÇÕES
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        7.1{"   "}
                        <Text>
                            As Partes signatárias declaram e garantem que:
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        7.1.1{"   "}
                        <Text>
                            Possuem plena capacidade e legitimidade para celebrar o presente Termo Cons&shy;titutivo, realizar todas as operações e cumprir todas as obrigações assumidas tendo tomado todas as medidas de natureza societária, conforme aplicável, e outras eventualmente necessárias para autorizar a sua celebração, implementação e cumprimento de todas as obrigações constituídas;
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        7.1.2{"   "}
                        <Text>
                             A celebração deste Termo Constitutivo e o cumprimento das obrigações nele dispostos: (a) não violam qualquer disposição contida nos seus documentos societários, conforme aplicável; (b) não violam qualquer lei,   regulamento,   decisão  judicial,     administrativa ou arbitral, aos quais a respectiva parte esteja vinculada; (c) não exigem qualquer consentimento ação ou autorização, prévia ou posterior, de terceiros;
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        7.1.3{"   "}
                        <Text>
                            Este Termo Constitutivo é validamente celebrado e constitui obrigação legal, válida, vinculante e exequível, de acordo com os seus termos;
                        </Text>
                    </Text>


                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        7.1.4{"   "}
                        <Text>
                            Estão aptas a cumprir as obrigações ora previstas neste Termo Constitutivo e agirão em relação às mesmas de boa-fé e com lealdade;

                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        7.1.5{"   "}
                        <Text>
                            Não se encontram em estado de necessidade ou sob coação para celebrar este Termo Constitutivo e/ou quaisquer contratos e compromissos a ele relacionados e
                            acessórios;
                        </Text>
                </Text>
                <View style={styles.section}>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        7.1.6{"   "}
                        <Text>
                            São devidamente estruturadas, qualificadas e capacitadas para entender a estrutura financeira e jurídica objeto deste Termo Constitutivo.
                        </Text>
                    </Text>
                    {/*<Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        8.{"   "}
                        <Text style={styles.textBold}>
                            DISPOSIÇÕES GERAIS
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.1{"   "}
                        <Text>
                            Este instrumento e as Notas Comerciais constituem títulos executivos extrajudiciais, nos termos dos incisos I e III do artigo 784 do
                            Código de Processo Civil, do inciso XI da Lei no 13.105 de 16 de março de 2015 e do artigo 47 da Lei no 14.195 de 26 de agosto de 2021,
                            as partes desde já que, independentemente de quaisquer outras medidas cabíveis, as obrigações assumidas nos termos deste instrumento e com relação às Notas Comerciais estão sujeitas à execução específica, submetendo-se às disposições dos artigos 815 e seguintes do Código de Processo Civil, sem prejuízo do direito de declarar o vencimento antecipado das Notas Comerciais, nos termos deste instrumento.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.2{"   "}
                        <Text>
                            A Nota Comercial e o presente instrumento são celebrados em caráter irrevogável e irretratável, obrigando as partes e seus sucessores a qualquer título.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.3{"   "}
                        <Text>
                            Não se presume a renúncia a qualquer dos direitos decorrentes da Nota Comercial. Desta forma, nenhum atraso, omissão ou liberalidade no exercício de qualquer direito, faculdade ou prerrogativa que caiba ao Titular, em razão de qualquer inadimplemento da Emitente, prejudicará o exercício de tais direitos, faculdades ou remédios, ou será interpretado como constituindo uma renúncia aos mesmos ou concordância com tal inadimplemento, nem constituirá novação ou modificação de quaisquer outras obrigações assumidas pela Emitente neste Termo de Emissão, ou precedente no tocante a qualquer outro inadimplemento ou atraso.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.4{"   "}
                        <Text>
                            O presente Termo de Emissão é firmado em caráter irrevogável e irretratável, obrigando as Partes por si e seus sucessores.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.5{"   "}
                        <Text>
                            Caso qualquer das disposições deste Termo de Emissão venha a ser julgada ilegal, inválida, ineficaz, nula ou inexequível, prevalecerão todas as demais disposições não afetadas por tal julgamento, comprometendo-se as Partes, em boa-fé, a substituir a disposição afetada por outra que, na medida do possível, produza o mesmo efeito.
                        </Text>
                </Text>*/}

                </View>
                <View style={[styles.footer, { position: 'absolute', bottom: 0 }]}>
                    <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                </View>
            </Page>
            <Page style={styles.page}>
                <View style={[styles.header, { alignItems: 'center' }]}>
                    <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '85%', marginBottom: '2px', height: '35px' }]} />
                </View>
                <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        8.{"   "}
                        <Text style={styles.textBold}>
                            DISPOSIÇÕES GERAIS
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.1{"   "}
                        <Text>
                            Este instrumento e as Notas Comerciais constituem títulos executivos extrajudiciais, nos termos dos incisos I e III do artigo 784 do
                            Código de Processo Civil, do inciso XI da Lei no 13.105 de 16 de março de 2015 e do artigo 47 da Lei no 14.195 de 26 de agosto de 2021,
                            as partes desde já que, independentemente de quaisquer outras medidas cabíveis, as obrigações assumidas nos termos deste instrumento e com relação às Notas Comerciais estão sujeitas à execução específica, submetendo-se às disposições dos artigos 815 e seguintes do Código de Processo Civil, sem prejuízo do direito de declarar o vencimento antecipado das Notas Comerciais, nos termos deste instrumento.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.2{"   "}
                        <Text>
                            A Nota Comercial e o presente instrumento são celebrados em caráter irrevogável e irretratável, obrigando as partes e seus sucessores a qualquer título.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.3{"   "}
                        <Text>
                            Não se presume a renúncia a qualquer dos direitos decorrentes da Nota Comercial. Desta forma, nenhum atraso, omissão ou liberalidade no exercício de qualquer direito, faculdade ou prerrogativa que caiba ao Titular, em razão de qualquer inadimplemento da Emitente, prejudicará o exercício de tais direitos, faculdades ou remédios, ou será interpretado como constituindo uma renúncia aos mesmos ou concordância com tal inadimplemento, nem constituirá novação ou modificação de quaisquer outras obrigações assumidas pela Emitente neste Termo de Emissão, ou precedente no tocante a qualquer outro inadimplemento ou atraso.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.4{"   "}
                        <Text>
                            O presente Termo de Emissão é firmado em caráter irrevogável e irretratável, obrigando as Partes por si e seus sucessores.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.5{"   "}
                        <Text>
                            Caso qualquer das disposições deste Termo de Emissão venha a ser julgada ilegal, inválida, ineficaz, nula ou inexequível, prevalecerão todas as demais disposições não afetadas por tal julgamento, comprometendo-se as Partes, em boa-fé, a substituir a disposição afetada por outra que, na medida do possível, produza o mesmo efeito.
                        </Text>
                </Text>
                <View style={[styles.section]}>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.6{"   "}
                        <Text>
                            O presente Termo de Emissão e as Notas Comerciais constituem título executivo
                            extrajudicial, nos termos do artigo 47 da Lei nº 14.195 de 26 de agosto de 2021 e do artigo 784, inciso XI da Lei nº 13.105 de 16 de março de 2015 (“Código de Processo Civil”),
                            reconhecendo as Partes desde já que, independentemente de quaisquer outras medidas cabíveis, as obrigações assumidas nos termos deste Termo de Emissão e com
                            relação às Notas Comerciais estão sujeitas a execução específica, submetendo-se às
                            disposições dos artigos 815 e seguintes do Código de Processo Civil, sem prejuízo do
                            direito de declarar o vencimento antecipado das Notas Comerciais, nos termos deste
                            Termo de Emissão.</Text>
                    </Text>
                   {/* <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.7{"   "}
                        <Text>
                            Exceto quando previsto expressamente de modo diverso na presente Escritura de Emissão, entende-se por “Dia(s) Útil(eis)” qualquer dia em que não seja feriado declarado nacional, sábado ou domingo.</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.8{"   "}
                        <Text>
                            Todas e quaisquer despesas incorridas com a Emissão e/ou com a execução de valores devidos nos termos deste Termo de Emissão, publicações, inscrições, re&shy;gistros, averbações, contratação dos prestadores de serviços envolvidos na Emissão, bem como quaisquer outros custos relacionados às Notas Comerciais, serão de res&shy;ponsabilidade exclusiva da Emitente, nos termos deste Termo de Emissão.</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.9{"   "}
                        <Text>
                            Assinatura Digital. As Partes reconhecem que as declarações de vontade das Partes contratantes mediante assinatura digital presumem-se verdadeiras em relação aos signatários quando é utilizado o processo de certificação disponibilizado pela Infraestrutura de Chaves Públicas Brasileira – ICP-Brasil, conforme admitido pelo artigo 10 e seu parágrafo primeiro da Medida Provisória nº 2.200, de 24 de agosto de 2001, em vigor no Brasil, reconhecendo essa forma de contratação em meio eletrônico, digital e informático como válida e plenamente eficaz, constituindo título executivo extrajudicial para todos os fins de direito. Na forma acima prevista, o presente Termo de Emissão, seus eventuais aditamentos, assim como os demais documentos relacionados à Emissão e/ou às Notas Comerciais, poderão ser assinados digitalmente por meio eletrônico conforme disposto nesta Cláusula.</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.9.1{"   "}
                        <Text>
                            Este Termo de Emissão e seus eventuais aditamentos produzirão efeitos para todas as Partes a partir das datas neles indicadas, ainda que uma ou mais Partes realizem a assinatura eletrônica em data posterior.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px', marginBottom: '20px'
                    }]}>
                        8.10{"   "}
                        <Text>
                            Lei Aplicável. Este Termo de Emissão é regido pelas leis da República Federativa do Brasil.
                        </Text>
                    </Text>
                     <View style={[styles.section]}>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '10px', marginBottom: '15px'
                    }]}>
                        8.11{"   "}
                        <Text>
                            Foro. As Partes elegem o foro da Comarca de São Paulo, Estado de São Paulo, com renúncia expressa de qualquer outro, por mais privilegiado que seja, para dirimir as questões porventura oriundas deste Termo de Emissão.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '10px'
                    }]}>E, por estarem assim justas e contratadas, as Partes celebram o presente Termo de Emissão, por meio de assinaturas digitais com certificação no padrão da Infraestrutura de Chaves Públicas Brasileira (ICP-Brasil). Uma vez assinada digitalmente pelas Partes, o presente Termo de Emissão devidamente assinado ficará disponível na plataforma digital, ficando cada Parte responsável por obter uma ou mais vias e mantê-la(s) em seus arquivos e registros.
                    </Text>
                  {}  <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px', marginBottom: '300px'
                    }]}>
                        As Partes signatárias reconhecem a forma de contratação por meios eletrônicos, digitais e informáticos como válida e plenamente eficaz, constituindo título executivo extrajudicial para todos os fins de direito.
                    </Text>
                </View>*/}


                </View>
                <View style={[styles.footer, { position: 'absolute', bottom: 0 }]}>
                    <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                </View>
            </Page>
            <Page style={styles.page}>
                <View style={[styles.header, { alignItems: 'center' }]}>
                    <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '85%', marginBottom: '2px', height: '35px' }]} />
                </View>
                <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.7{"   "}
                        <Text>
                            Exceto quando previsto expressamente de modo diverso na presente Escritura de Emissão, entende-se por “Dia(s) Útil(eis)” qualquer dia em que não seja feriado declarado nacional, sábado ou domingo.</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.8{"   "}
                        <Text>
                            Todas e quaisquer despesas incorridas com a Emissão e/ou com a execução de valores devidos nos termos deste Termo de Emissão, publicações, inscrições, re&shy;gistros, averbações, contratação dos prestadores de serviços envolvidos na Emissão, bem como quaisquer outros custos relacionados às Notas Comerciais, serão de res&shy;ponsabilidade exclusiva da Emitente, nos termos deste Termo de Emissão.</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.9{"   "}
                        <Text>
                            Assinatura Digital. As Partes reconhecem que as declarações de vontade das Partes contratantes mediante assinatura digital presumem-se verdadeiras em relação aos signatários quando é utilizado o processo de certificação disponibilizado pela Infraestrutura de Chaves Públicas Brasileira – ICP-Brasil, conforme admitido pelo artigo 10 e seu parágrafo primeiro da Medida Provisória nº 2.200, de 24 de agosto de 2001, em vigor no Brasil, reconhecendo essa forma de contratação em meio eletrônico, digital e informático como válida e plenamente eficaz, constituindo título executivo extrajudicial para todos os fins de direito. Na forma acima prevista, o presente Termo de Emissão, seus eventuais aditamentos, assim como os demais documentos relacionados à Emissão e/ou às Notas Comerciais, poderão ser assinados digitalmente por meio eletrônico conforme disposto nesta Cláusula.</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.9.1{"   "}
                        <Text>
                            Este Termo de Emissão e seus eventuais aditamentos produzirão efeitos para todas as Partes a partir das datas neles indicadas, ainda que uma ou mais Partes realizem a assinatura eletrônica em data posterior.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px', marginBottom: '20px'
                    }]}>
                        8.10{"   "}
                        <Text>
                            Lei Aplicável. Este Termo de Emissão é regido pelas leis da República Federativa do Brasil.
                        </Text>
                    </Text>
                    <View style={[styles.section]}>
                        <Text style={[styles.textJustify, {
                            fontSize: 12,
                            marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                            marginTop: '10px', marginBottom: '15px'
                        }]}>
                            8.11{"   "}
                            <Text>
                                Foro. As Partes elegem o foro da Comarca de São Paulo, Estado de São Paulo, com renúncia expressa de qualquer outro, por mais privilegiado que seja, para dirimir as questões porventura oriundas deste Termo de Emissão.
                            </Text>
                        </Text>
                        <Text style={[styles.textJustify, {
                            fontSize: 12,
                            marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                            marginTop: '10px', marginBottom: '15px'
                        }]}>
                            <Text style={[styles.textJustify, {
                            fontSize: 12,
                            marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                            marginTop: '10px', marginBottom: '15px'
                        }]}>
                                E, por estarem assim justas e contratadas, as Partes celebram o presente Termo de Emissão, por meio de assinaturas digitais com certificação no padrão da Infraestrutura de Chaves Públicas Brasileira (ICP-Brasil). Uma vez assinada digitalmente pelas Partes, o presente Termo de Emissão devidamente assinado ficará disponível na plataforma digital, ficando cada Parte responsável por obter uma ou mais vias e mantê-la(s) em seus arquivos e registros
                            </Text>
                            
                        </Text>
                           
                        
                    </View>
                    
                <View style={[styles.footer, { position: 'absolute', bottom: 0 }]}>
                    <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                </View>
            </Page>
            <Page style={styles.page}>
                <View style={[styles.header, { alignItems: 'center' }]}>
                    <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '85%', marginBottom: '2px', height: '35px' }]} />
                </View>
                
                <View style={[styles.section]}>
                    <Text style={[styles.textJustify, {
                            fontSize: 12,
                            marginVertical: '0px', marginHorizontal: '20px',
                            marginTop: '20px',
                        }]}>
                             As Partes signatárias reconhecem a forma de contratação   por   meios    eletrônicos, digitais e informáticos como válida e plenamente eficaz, constituindo título executivo extrajudicial para todos os fins de direito.
                    </Text>
                    <Text style={[styles.textCenter, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px', marginTop: '20px', marginBottom: '10px'
                    }]}>
                        {emissor.cidade}/{emissor.estado}, {dataEmissao}
                    </Text>
                    <Text style={[styles.text1, { marginTop: 30, marginHorizontal: '20px' }]}>
                        {""}
                    </Text>
                    <Text style={[styles.text2, { marginTop: 20, marginHorizontal: '20px', marginBottom: '10px' }]}>
                        {emissor.razaoSocial}{'\n'}
                    </Text>
                    <Text style={[styles.text1, { marginHorizontal: '20px', marginTop: '0px' }]}>
                        EMITENTE

                    </Text>
                    {avalistas.map((avalista, index) => (
                        <View style={[styles.view2, { marginBottom: 5 }]} key={index}>
                            <Text style={[styles.text2, { marginTop: 10, marginHorizontal: '20px' }]}>{avalista.aNome}</Text>
                            <Text style={{ marginHorizontal: '20px', marginTop: '10px' }}>AVALISTA</Text>
                        </View>
                    ))}
                    <Text style={[styles.text2, { marginTop: 20, marginHorizontal: '20px', marginBottom: '10px' }]}>
                        TRUSTEE DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA{'\n'}
                    </Text>
                    <Text style={[styles.text1, { marginHorizontal: '20px', marginBottom: '10px' }]}>
                        ESCRITURADOR
                    </Text>

                    <Text style={[styles.text2, { marginTop: 20, marginHorizontal: '20px', marginBottom: '10px' }]}>
                        {investidor.razaoSocial}{'\n'}
                    </Text>
                    <Text style={[styles.text1, { marginHorizontal: '20px', marginBottom: '10px' }]}>
                        TITULAR
                    </Text>

                </View>
                <View style={[styles.footer, { position: 'absolute', bottom: 0 }]}>
                    <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                </View>
            </Page>
            {emissor.garantia ?
                <Page style={styles.page}>
                    <View style={[styles.header, { alignItems: 'center' }]}>
                        <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '85%', marginBottom: '2px', height: '35px' }]} />
                    </View>
                    <View style={styles.section}>
                        <Text style={[styles.textCenter, { marginTop: 60, marginBottom: '20px' }]}>
                            GARANTIAS
                        </Text>
                        <View style={styles.tableContainer}>
                            <View style={styles.row}>
                                <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', borderBottomWidth: '1px', width: '100%' }]}>TIPO DE GARANTIA</Text>
                                <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', borderBottomWidth: '1px', width: '80%' }]}>NATUREZA</Text>
                                <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', borderBottomWidth: '1px', width: '80%' }]}>QUANTIDADE</Text>
                                <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', borderBottomWidth: '1px' }]}>CPF/CNPJ</Text>
                                <Text style={[styles.cell3, { border: '0px', borderRightWidth: '0px', borderBottomWidth: '1px' }]}>PROPRIETÁRIO (A)</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', width: '100%' }]}>
                                    {emissor.gTipo}
                                </Text>
                                <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', width: '80%' }]}>
                                    {emissor.gNatureza}
                                </Text>
                                <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', width: '80%' }]}>
                                    {emissor.gQuantidade}
                                </Text>
                                <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px' }]}>
                                    {emissor.gDocumento}
                                </Text>
                                <Text style={[styles.cell3, { border: '0px', borderRightWidth: '0px' }]}>
                                    {emissor.gProprietario}
                                </Text>
                            </View>
                        </View>
                        <Text style={[styles.text1, { marginTop: '40px', marginHorizontal: '40px' }]}>
                            Descrição das garantias:
                        </Text>
                        <Text style={[styles.text1, { marginTop: '10px', marginHorizontal: '40px' }]}>
                            {emissor.gDescricao}
                        </Text>
                    </View>
                    <View style={[styles.footer, { position: 'absolute', bottom: 0 }]}>
                        <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                    </View>
                </Page> : null}
            <Page style={styles.page}>
                <View style={[styles.header, { alignItems: 'center' }]}>
                    <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '85%', marginBottom: '2px', height: '35px' }]} />
                </View>
                <View style={styles.section}>
                    <Text style={[styles.textBold, { marginTop: '2px', textAlign: 'center', textDecoration: 'underline', fontSize: 12, marginBottom: '10px' }]}>
                        ANEXO I – BOLETIM DE SUBSCRIÇÃO DAS NOTAS COMERCIAIS
                    </Text>
                    <Text style={[styles.textBold, { marginTop: '2px', fontSize: 12, textAlign: 'center' }]}>
                        BOLETIM DE SUBSCRIÇÃO DA {dados.emissao} EMISSÃO DE NOTAS COMERCIAIS, SEM GARANTIA REAL, EM SÉRIE ÚNICA, PARA DISTRIBUIÇÃO PRIVADA COM ESFORÇOS RESTRITOS, DA {emissor.razaoSocial.toUpperCase()}
                    </Text>
                    <View style={[styles.tableContainer]}>


                        <View style={[styles.tableContainer, { border: '0px' }]}>
                            <Text style={[styles.text1, { margin: '5px' }]}>
                                Emissão, pela {emissor.razaoSocial}, empresa individual de responsabilidade limitada, com sede em {emissor.rua}, {emissor.numero}, {emissor.complemento ? emissor.complemento + ',' : ''} {emissor.bairro}, {emissor.cidade} do estado de {emissor.estado}, CEP {emissor.cep}, inscrita no CNPJ/ME sob nº {emissor.cnpj}.{'\n'}
                                de 1 (um) notas comerciais, com valor nominal unitário de {allInfoArray[13]}, vencimento em {dataDoVencimento}, parcelada em {parcelas} parcela(s) apresentada(s) no plano de pagamento em anexo, da sua [{dados.emissao}] emissão. (“Notas Comerciais “e “Emissão”)
                            </Text>
                            <Text style={[styles.text1, { borderTopWidth: '1px', paddingVertical: '5px', borderBottomWidth: '1px', backgroundColor: '#d9d9d9', textAlign: 'center', fontSize: 10, fontFamily: 'Helvetica-Bold' }]}>
                                QUALIFICAÇÃO SUBSCRITOR E SEU REPRESENTANTE
                            </Text>
                            <View style={[styles.row]}>
                                <Text style={[styles.cell3, { border: '0px', borderBottomWidth: '1px', borderRightWidth: '1px', width: '25%', fontSize: 10 }]}>
                                    Razão Social:
                                </Text>
                                <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', width: '50%', fontSize: '10px', borderBottomWidth: '1px' }]}>
                                    {investidor.razaoSocial}
                                </Text>
                                <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', width: '25%', fontSize: '10px', borderBottomWidth: '1px' }]}>
                                    CNPJ:
                                </Text>
                                <Text style={[styles.cell3, { border: '0px', width: '50%', fontSize: '10px', borderBottomWidth: '1px' }]}>
                                    {formatCnpj(investidor.cnpj)}
                                </Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell3, { border: '0px', borderBottomWidth: '1px', borderRightWidth: '1px', width: '25%' }]}>Endereço:
                                </Text>
                                <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', width: '50%', fontSize: '10px', borderBottomWidth: '1px' }]}>
                                    {investidor.rua}, {investidor.cidade}, {investidor.estado}
                                </Text>
                                <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', width: '25%', borderBottomWidth: '1px' }]}>
                                    Telefone:
                                </Text>
                                <Text style={[styles.cell3, { border: '0px', width: '50%', borderBottomWidth: '1px' }]}>
                                    {investidor.telefone}
                                </Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', width: '25%' }]}>
                                    Representado por:
                                </Text>
                                <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', width: '50%', fontSize: '10px' }]}>
                                    {investidor.representantes.map((representante, index) => (
                                        <React.Fragment key={index}>
                                            {representante.nome}
                                            {index < investidor.representantes.length - 1 && '\n'}
                                        </React.Fragment>
                                    ))}
                                </Text>

                                <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', width: '25%' }]}>
                                    País:
                                </Text>
                                <Text style={[styles.cell3, { border: '0px', width: '50%' }]}>
                                    Brasil
                                </Text>
                            </View>
                            <Text style={[styles.text1, { borderTopWidth: '1px', padding: '10px', backgroundColor: '#d9d9d9', textAlign: 'center', fontSize: 10, fontFamily: 'Helvetica-Bold' }]}>
                                NOTAS COMERCIAIS SUBSCRITAS
                            </Text>
                            <View style={[styles.row, { backgroundColor: '#d9d9d9' }]}>

                                <Text style={[styles.cell1, { borderTopWidth: '1px', borderBottomWidth: '1px', fontSize: '10px', borderLeftWidth: '0px' }]}>
                                    QUANTIDADE
                                </Text>
                                <Text style={[styles.cell1, { borderTopWidth: '1px', borderBottomWidth: '1px' }]}>
                                    PREÇO UNITÁRIO
                                </Text>
                                <Text style={[styles.cell1, { borderTopWidth: '1px', borderBottomWidth: '1px' }]}>
                                    VALOR EM R$
                                </Text>
                            </View>
                            <View style={[styles.row]}>
                                <Text style={[styles.cell1, { fontSize: '10px', borderLeftWidth: '0px' }]}>
                                    1
                                </Text>
                                <Text style={[styles.cell1, {}]}>
                                    {allInfoArray[13]}
                                </Text>
                                <Text style={[styles.cell1, {}]}>
                                    {allInfoArray[13]}
                                </Text>
                            </View>
                            <View style={[styles.text3]}>
                                <Text style={[{
                                    borderTopWidth: '1px', textAlign: 'center', borderRightWidth: '0px', borderLeftWidth: '0px', marginBottom: '0px', verticalAlign: 'sub'
                                }]}>
                                    A rentabilidade de cada nota comercial se inicia a partir da data da efetiva integralização.
                                </Text>
                            </View>
                        </View>
                        <Text style={[styles.text1, { borderTopWidth: '1px', padding: '2px', backgroundColor: '#d9d9d9', textAlign: 'center', fontSize: 10, fontFamily: 'Helvetica-Bold', borderBottomWidth: '1px' }]}>
                            FORMA DE SUBSCRIÇÃO E INTEGRALIZAÇÃO
                        </Text>
                        <View style={styles.row}>
                            <Text style={[styles.cell, { borderRightWidth: '1px', borderBottomWidth: '1px', width: '50%' }]}>
                                MEIO DE INTEGRALIZAÇÃO
                            </Text>
                            <Text style={[styles.cell, { borderBottomWidth: '1px', width: '50%' }]}>
                                DATA DE INTEGRALIZAÇÃO
                            </Text>
                        </View>
                        <View style={[styles.row]}>
                            <Text style={[styles.cell, { borderRightWidth: '1px', borderBottomWidth: '1px', width: '50%' }]}>
                                {emissor.meioPagamento}
                            </Text>
                            <Text style={[styles.cell, { borderBottomWidth: '1px', width: '50%' }]}>
                                A Efetiva integralização deverá ser realizada no máximo em 30 (trinta) dias corridos contados da data da assinatura deste Boletim de Subscrição.
                            </Text>
                        </View>
                        <Text style={[styles.text1, { borderTopWidth: '1px', padding: '5px', backgroundColor: '#d9d9d9', textAlign: 'center', fontSize: 10, fontFamily: 'Helvetica-Bold' }]}>
                            CONTA BANCÁRIA DA EMITENTE
                        </Text>
                        <View style={styles.row}>
                            <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px', borderRight: '1px', width: '50%' }]}>
                                BANCO
                            </Text>
                            <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px', borderRight: '1px', width: '30%' }]}>
                                Nº do Banco
                            </Text>
                            <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px', borderRight: '1px', width: '30%' }]}>
                                Nº Agência
                            </Text>
                            <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px', borderRight: '1px' }]}>
                                Nº da conta
                            </Text>
                            <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px' }]}>
                                Valor
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={[styles.cell, { borderRight: '1px', width: '50%' }]}>
                                {emissor.banco}
                            </Text>
                            <Text style={[styles.cell, { borderRight: '1px', width: '30%' }]}>
                                {emissor.bankNumber}
                            </Text>
                            <Text style={[styles.cell, { borderRight: '1px', width: '30%' }]}>
                                {emissor.agency}
                            </Text>
                            <Text style={[styles.cell, { borderRight: '1px' }]}>
                                {emissor.conta}
                            </Text>
                            <Text style={[styles.cell, {}]}>
                                {allInfoArray[5]}
                            </Text>
                        </View>
                        <Text style={[styles.text1, { borderTopWidth: '1px', padding: '2px', backgroundColor: '#d9d9d9', textAlign: 'center', fontSize: 10, fontFamily: 'Helvetica-Bold' }]}>
                            RESPONSÁVEL PELA ESCRITURAÇÃO
                        </Text>
                        <View style={styles.row}>
                            <Text style={[styles.cell, { fontSize: 9, borderTop: '1px', width: '70%' }]}>
                                TRUSTEE DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA
                            </Text>

                            <Text style={[styles.cell, { fontSize: 9, borderTop: '1px', borderLeft: '1px', width: '30%' }]}>
                                CNPJ: 67.030.395/0001-46
                            </Text>
                        </View>
                        <Text style={[styles.text1, { borderTopWidth: '1px', padding: '2px', backgroundColor: '#d9d9d9', textAlign: 'center', fontSize: 10, fontFamily: 'Helvetica-Bold' }]}>
                            RESPONSÁVEL PELA ESTRUTURAÇÃO DA NOTA COMERCIAL
                        </Text>
                        <View style={styles.row}>
                            <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px', borderRight: '1px', width: '50%' }]}>
                                RAZÃO SOCIAL
                            </Text>
                            <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px', borderRight: '1px', width: '30%' }]}>
                                Nº do Banco
                            </Text>
                            <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px', borderRight: '1px', width: '30%' }]}>
                                Nº Agência
                            </Text>
                            <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px', borderRight: '1px' }]}>
                                Nº da conta
                            </Text>
                            <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px' }]}>
                                Valor
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={[styles.cell, { borderRight: '1px', width: '50%' }]}>
                                CRED2CARDS LTDA
                            </Text>
                            <Text style={[styles.cell, { borderRight: '1px', width: '30%' }]}>
                                077
                            </Text>
                            <Text style={[styles.cell, { borderRight: '1px', width: '30%' }]}>
                                0001
                            </Text>
                            <Text style={[styles.cell, { borderRight: '1px' }]}>
                                23203346-3
                            </Text>
                            <Text style={[styles.cell, {}]}>
                                {valorC3.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </Text>
                        </View>
                        <Text style={[styles.text1, { borderTopWidth: '1px', padding: '10px', textAlign: 'center', fontSize: 10 }]}>
                            Declaro para os devidos fins que estou de acordo com as condições expressas no presente Boletim de Subscrição, bem como declaro ter obtido exemplar do Termo Constitutivo.

                        </Text>
                    </View>
                    <Text style={[styles.text1, { padding: '5px', textAlign: 'center', fontSize: 10, paddingTop: '5px' }]}>
                        {emissor.cidade}, {dataEmissao}
                    </Text>
                    <Text style={[styles.text2, { padding: '5px', textAlign: 'center', fontSize: 10, marginTop: '0px' }]}>
                        Assinatura do Representante Legal do Subscritor
                    </Text>
                </View>
            </Page>
            {chunkedCalcData.map((chunk, pageIndex) => (
                <Page style={styles.page}>
                    <View style={[styles.header, { alignItems: 'center' }]}>
                        <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '80%', marginBottom: '0px', height: '35px' }]} />
                    </View>
                    <View style={styles.section}>
                        {isFirstPage && (
                            <View>
                                <Text style={[styles.textBold, { textAlign: 'center', textDecoration: 'underline', marginBottom: '20px', fontSize: 12 }]}>
                                    ANEXO II – PLANO DE PAGAMENTO
                                </Text>
                                <View style={styles.downTable}>
                                    <View style={styles.tr}>
                                        <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '70px' }]}>Período</Text>
                                        <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '82px' }]}>Vencimento</Text>
                                        <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '90px' }]}>Parcela</Text>
                                        <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '91px' }]}>Amortização</Text>
                                        <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '88px' }]}>Juros</Text>
                                        <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '100px' }]}>Saldo Devedor</Text>
                                    </View>
                                </View>
                                <View style={styles.tr}>
                                    <Text style={[styles.centralizado, { borderLeftWidth: '1px', borderRightWidth: '1px', borderRightColor: 'black', width: '71px' }]}>0</Text>
                                    <Text style={[styles.centralizado, { borderRightWidth: '1px', borderRightColor: 'black', width: '82px' }]}>
                                        {now.getDate().toString().padStart(2, '0')}/{(now.getMonth() + 1).toString().padStart(2, '0')}/{now.getFullYear()}
                                    </Text>
                                    <Text style={[styles.alinhamentoDireitaTd, { width: '90px' }]}>0</Text>
                                    <Text style={[styles.alinhamentoDireitaTd, { width: '91px' }]}>0</Text>
                                    <Text style={[styles.alinhamentoDireitaTd, { width: '88px' }]}>0</Text>
                                    <Text style={[styles.alinhamentoDireitaTd, { width: '100px' }]}>{allInfoArray[13]}</Text>
                                </View>

                            </View>
                        )}
                        {chunk.map((data, index) => (
                            <View key={index} style={[styles.tr, { borderTopWidth: "1px" }]}>
                                <Text style={[styles.centralizado, { borderLeftWidth: '1px', borderRightWidth: '1px', borderRightColor: 'black', width: '71px' }]}>{data[0]}</Text>
                                <Text style={[styles.centralizado, { borderRightWidth: '1px', borderRightColor: 'black', width: '82px' }]}>
                                    {data[1] < 10 ? `0${data[1]}` : data[1]}/{data[2] === '00' ? 12 : data[2]}/{data[3]}
                                </Text>
                                <Text style={[styles.alinhamentoDireitaTd, { width: '90px' }]}>
                                    {data[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </Text>
                                <Text style={[styles.alinhamentoDireitaTd, { width: '91px' }]}>
                                    {data[5].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </Text>
                                <Text style={[styles.alinhamentoDireitaTd, { width: '88px' }]}>
                                    {data[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </Text>
                                <Text style={[styles.alinhamentoDireitaTd, { width: '100px' }]}>
                                    {data[7].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </Text>

                            </View>

                        ))}
                    </View>

                    {isFirstPage && (pageIndex === 0 ? (isFirstPage = false) : null)}
                </Page>
            ))}
        </Document>
    )
}

export default TrusteeSemGarantia;