import { React, useState } from "react";
import '../styles/Account.css'
import { useSelector } from "react-redux";
import axios from "axios";
import Header from "../components/Header";
import { formatCnpj } from "../utils/Tools";

const Account = () => {

    const [cpf, setCpf] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const dados = useSelector((state) => state.dados.dados);
    const userTokenString = localStorage.getItem('user');
    const user = JSON.parse(userTokenString);

    const formatCpf = (text) => {
        console.log(text);
        const cpf = text.replace(/\D/g, '');
        let formattedCpf = '';

        // Formatação do CPF: XXX.XXX.XXX-XX
        for (let i = 0; i < cpf.length; i++) {
            if (i === 3 || i === 6) {
                formattedCpf += '.';
            } else if (i === 9) {
                formattedCpf += '-';
            }
            formattedCpf += cpf[i];
        }

        return formattedCpf;
    };

    const handleSaveChanges = () => {
        console.log('====================================');
        console.log(cpf, newPassword);
        console.log('====================================');
        const apiUrl = process.env.REACT_APP_BASE_URL;

        const requestBody = {
            cpf: cpf,
            password: newPassword,
        };
        const headers = {
        };
        axios
            .put(`${apiUrl}`, requestBody, headers)
            .then((response) => {
                console.log("Senha atualizada com sucesso!");
                window.alert('Senha atualizada com sucesso!')
            })
            .catch((error) => {
                window.alert('Erro ao atualizar a senha')
                console.error("Erro ao atualizar a senha:", error);
                // Adicione aqui qualquer tratamento adicional que você queira fazer para lidar com o erro
            });
    };

    return (
        <body className="homeBody">
            <div className="fixed">
                <Header selected='perfil' />
            </div>
            <main className="accountMain">
                <div className="ahead">
                    <div className="div1">
                        <h1>Perfil</h1>

                    </div>

                    <div className="div1">
                    </div></div>
                <div className="whiteBackground">
                    <h2>Informações Pessoais</h2>
                    <div className="divCadastroAccounts">
                        <div className="divInputs">
                            <label className='label'>Razão Social</label>
                            <h3
                                className="inputAccount"
                            > {user.data.razaoSocial}</h3>
                        </div>
                        <div className="divInputs">
                            <label className='label'>
                                CNPJ</label>
                            <h3
                                className="inputAccount"
                            >{formatCnpj(user.data.cnpj)}</h3>
                        </div>
                        <div className="divInputs">
                            <label className='label'>
                                Email</label>
                            <h3
                                className="inputAccount"
                            >{user.data.email}</h3>
                        </div>
                    </div>
                    <h3>Gostaria de alterar sua senha?</h3>
                    <div className="divCad1">
                        <div className="inputWrapper1">
                            <label className="labelPass1">E-mail</label>
                            <input
                                maxLength={14}
                                className="inputPass1"
                                value={cpf}
                                type="text"
                                placeholder="email@example.com"
                                onChange={(e) => { setCpf(e.target.value) }} />
                        </div>
                        <div className="inputWrapper1">
                            <label className="labelPass">Nova Senha</label>
                            <input
                                className="inputPass1"
                                type="password"
                                placeholder="Insira a sua nova senha"
                                onChange={(e) => { setNewPassword(e.target.value) }}
                            />
                        </div>
                        <button className="button1" onClick={handleSaveChanges}>Atualizar Senha</button>
                    </div>
                </div>
            </main>
        </body>
    );
};

export default Account;