// actions.js
import { createAction } from '@reduxjs/toolkit';

export const setJurosAcumulados = createAction('jurosAcumulados/setJurosAcumulados', (jurosAcumulados) => {
    console.log('juros colocado');
    return { payload: jurosAcumulados };
})

export const setCalcData = createAction('calcData/setCalcData', (calcData) => {
    console.log('calc data');
    return { payload: calcData };
})

export const setSacData = createAction('sacData/setSacData', (sacData) => {
    console.log('colocou sac data');
    return { payload: sacData };
})

export const setDados = createAction('dados/setDados', (dados) => {
    console.log('dados colocado');
    return { payload: dados };
});

export const setInvestidor = createAction('dados/setInvestidor', (investidor) => {
    console.log('investidor colocado');
    return { payload: investidor };
});

export const setEmissor = createAction('dados/setEmissor', (emissor) => {
    console.log('investidor colocado');
    return { payload: emissor };
});

export const setFinalTotal = createAction('finalTotal/setFinalTotal', (finalTotal) => {
    console.log('finalTotal colocado');
    return { payload: finalTotal };
})

export const setHtml = createAction('html/setHtml', (html) => {
    console.log('html colocado');
    return { payload: html };
});

export const setAttachment = createAction('attachments/setAttachment', (fieldName, file) => ({
    payload: { fieldName, file },
}));

// Ação para limpar um campo de arquivo
export const clearAttachment = createAction('attachments/clearAttachment', (fieldName) => ({
    payload: { fieldName },
}));