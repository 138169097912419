import { FaUserGear, FaClipboardCheck, FaFilePdf, FaCalculator, FaRightFromBracket, FaClipboardUser, FaFileInvoiceDollar, FaBattleNet, FaBrazilianRealSign, FaBriefcase, FaNoteSticky, FaEvernote, FaNetworkWired } from "react-icons/fa6";
import '../styles/Header.css'
import { HiOutlineUserGroup } from 'react-icons/hi'
import { BsCalculatorFill, BsFileEarmarkPerson, BsFileEarmarkCheck, BsFillFileBreakFill, BsCreditCard2Front } from 'react-icons/bs'
import { AiOutlineFileText } from "react-icons/ai"
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCalcData, setDados, setSacData } from "../redux/actions";
import { MdOutlineFileOpen } from "react-icons/md"
import { PiCurrencyCircleDollarLight } from "react-icons/pi"
import { MdLogout } from "react-icons/md"

const Header = ({ selected }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const calcData = useSelector((state) => state.calcData.calcData)
    const sacData = useSelector((state) => state.sacData.sacData)
    const dispatch = useDispatch()
    const handleLogout = () => {
        dispatch(setDados(false));
        dispatch(setCalcData(false));
        dispatch(setSacData(false));
        localStorage.setItem('emissorPreenchido', false);
        localStorage.setItem('investidorPreenchido', false);
        navigate('/')
    };
    const navigate = useNavigate()
    return (
        <header className={user.data.cnpj === '67030395000146' ? "headerHome2" : "headerHome1"}>
            <div className="divHeader1">
                {user.data.cnpj === '67030395000146' ?
                    <img src="https://github.com/PRO-Integra/Imagens/blob/main/C2Cards/trustee_dtvm_logo.jpeg?raw=true" alt="logo" className="headerImg1" />
                    :
                    <img src="https://github.com/PRO-Integra/Imagens/blob/main/C2Cards/c2cards_logo_negativo_1.png?raw=true" alt="logo" className="headerImg1" />
                }
            </div>

            <div className="headerContainer1">
                {user.data.role === 'Master' && <div className={selected === 'master' ? 'headerBar' : 'headerBar2'} onClick={() => { navigate('/inicio') }}>
                    <HiOutlineUserGroup color="white" size={20} /><h3 className="headerH11">Gerenciar Usuários</h3>
                </div>}
                <div className={selected === 'inicio' ? 'headerBar' : 'headerBar2'} onClick={() => { navigate('/home') }}>
                    <BsCalculatorFill color="white" size={20} /><h3 className="headerH11"> Simular Cálculo</h3>
                </div>
                <div className={selected === 'result' ? 'headerBar' : 'headerBar2'} onClick={() => { navigate('/result') }}>
                    <MdOutlineFileOpen color="white" size={20} /><h3 className="headerH11">Cenário Desenvolvido</h3>
                </div>

                <div className={selected === 'investidor' ? 'headerBar' : 'headerBar2'} onClick={() => { navigate('/investidor') }}>
                    <PiCurrencyCircleDollarLight color="white" size={20} /><h3 className="headerH11">Investidores</h3>
                </div>
                <div className={selected === 'emissor' ? 'headerBar' : 'headerBar2'} onClick={() => { navigate('/emissor') }}>
                    <BsFileEarmarkPerson color="white" size={20} /><h3 className="headerH11">Emissores</h3>
                </div>
                <div className={selected === 'garantias' ? 'headerBar' : 'headerBar2'} onClick={() => { navigate('/garantias') }}>
                    <BsCreditCard2Front color="white" size={20} /><h3 className="headerH11">Garantias</h3>
                </div>
                <div className={selected === 'docgen' ? 'headerBar' : 'headerBar2'} onClick={() => { navigate('/docgen') }}>
                    <AiOutlineFileText color="white" size={20} /><h3 className="headerH11">Emitir Nota Comercial</h3>
                </div>
                <div className={selected === 'notes' ? 'headerBar' : 'headerBar2'} onClick={() => { navigate('/emitted') }}>
                    <BsFileEarmarkCheck color="white" size={20} /><h3 className="headerH11">Notas Emitidas</h3>
                </div>
                <div className={selected === 'billets' ? 'headerBar' : 'headerBar2'} onClick={() => { navigate('/billets') }}>
                    <BsFillFileBreakFill color="white" size={20} /><h3 className="headerH11">Boletos</h3>
                </div>
                <div className={selected === 'perfil' ? 'headerBar' : 'headerBar2'} onClick={() => { navigate('/account') }}>
                    <HiOutlineUserGroup color="white" size={20} /><h3 className="headerH11">Perfil</h3>
                </div>
                <div className="logout" onClick={handleLogout}>
                    <MdLogout size={20} className='iconCalc' /><h3 className='headerH11'>Sair</h3>
                </div>
            </div>
        </header>
    )
}

export default Header;