import axios from "axios";

const notaIt4 = (dados) => {
    console.log('chamou', dados)
    const url = 'http://localhost:6024/it4/nota/';
    console.log(url)

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };


    axios.post(url,  JSON.stringify(dados.dataIt4), { headers: headers })
        .then(response => {
            window.alert('Resposta:', response);

        })
        .catch(error => {
            window.alert(`Erro: ${error.response.data.error}`);
        });
}


export default notaIt4;
